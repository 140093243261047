import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, MenuProps, Space, Typography } from "antd";
import React, { useMemo } from "react";
import { logoutAction, selectUser } from "../../Auth/state/auth.slice";
import { useAppDispatch, useAppSelector } from "../hooks";

const { Text } = Typography;

const ProfileDropdown: React.FC = () => {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  // méthode pour avoir les initiales du prénom et du nom du user
  const getInitials = (name: string) => {
    const names = name.split(" ");
    let initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  // générer une couleur aléatoire pour l'avatar (une seule fois)
  const avatarColor = useMemo(() => {
    return "#" + Math.floor(Math.random() * 16777215).toString(16);
  }, []); // La couleur est générée une seule fois

  const items: MenuProps['items'] = [
    {
      key: 'profile',
      label: (
        <Space>
          <Avatar style={{ backgroundColor: user?.firstName && user?.lastName ? avatarColor : "#C79D79" }}>
            {user?.firstName && user?.lastName ? getInitials(`${user.firstName} ${user.lastName}`) : <UserOutlined />}
          </Avatar>
          <Text strong>
            {user?.firstName && user?.lastName ? `${user.firstName} ${user.lastName}` : "Utilisateur"}
          </Text>
        </Space>
      ),
      disabled: true, // Désactiver l'élément du profil
    },
    { type: 'divider' }, // Diviseur entre les éléments
    {
      key: 'email',
      label: <Text type="secondary">{user?.email}</Text>,
      disabled: true,
    },
    {
      key: 'logout',
      label: (
        <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => dispatch(logoutAction())}>
          <LogoutOutlined />
          <span style={{ marginLeft: '8px' }}>Déconnexion</span>
        </div>
      ),
    },
  ];

  return (
    <div className="flex space-x-2">
      <Dropdown menu={{ items }} placement="bottomRight" trigger={['hover']}>
        <Avatar
          style={{
            backgroundColor: user?.firstName && user?.lastName ? avatarColor : "#C79D79",
            cursor: "pointer",
          }}
        >
          {user?.firstName && user?.lastName ? getInitials(`${user.firstName} ${user.lastName}`) : <UserOutlined />}
        </Avatar>
      </Dropdown>
    </div>
  );
};

export default ProfileDropdown;
