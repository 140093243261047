import PageCanvas from "../common/components/Panels/PageCanvas";
import InvoiceList from "./InvoiceList";

const InvoicePage = () => {
  return (
    <>
      <PageCanvas title="Vos factures" titleAlignment="center">
        <PageCanvas.Content>
          <InvoiceList />
        </PageCanvas.Content>
      </PageCanvas>
    </>
  );
};

export default InvoicePage;
