import { notification } from "antd";

// Types de notifications possibles
const notificationTypes = {
  success: "success",
  error: "error",
  info: "info",
  warning: "warning",
} as const;

type NotificationType = keyof typeof notificationTypes;

// Fonction utilitaire pour afficher une notification
const openNotification = (
  type: NotificationType,
  message: string,
  description?: string
) => {
  notification[type]({
    message: message,
    description: description,
    placement: "bottomRight",
    duration: 3, // Durée de la notification en secondes
  });
};

// Fonction pour afficher une notification de succès
const showSuccessNotification = (message: string, description?: string) => {
  openNotification(notificationTypes.success, message, description);
};

// Fonction pour afficher une notification d'erreur
const showErrorNotification = (message: string, description?: string) => {
  openNotification(notificationTypes.error, message, description);
};

// Fonction pour afficher une notification d'information
const showInfoNotification = (message: string, description?: string) => {
  openNotification(notificationTypes.info, message, description);
};

// Fonction pour afficher une notification d'avertissement
const showWarningNotification = (message: string, description?: string) => {
  openNotification(notificationTypes.warning, message, description);
};

export {
  showSuccessNotification,
  showErrorNotification,
  showInfoNotification,
  showWarningNotification,
};
