import { AppAuth } from "../../Auth/types";
import apiClient from "../../common/services/apiClient";
import ApiService from "../../common/services/apiService";
import { trackProgress } from "../../common/services/trackProgress";
import { AppUser } from "../types";
import { User } from "../types/modelUser";

const USER_PATH_API = 'user';

class UserApi extends ApiService<AppUser> {
  constructor() {
    super(USER_PATH_API)
  }

  /**
   * @description send a request for a new password via email link
   * @param email 
   * @returns 
   */
  askForPassword(email: string) {
    return this.custom('put', 'forgotPassword', undefined, { email })
  }

  /**
   * @description change password with unique token
   * @param token 
   * @param password 
   * @returns 
   */
  changePassword(token: string, password: string) {
    return this.custom('put', 'forgotPassword', undefined, { token, password })
  }

  getMyProfile() {
    return this.get('me')
      .then(user => new User(user))
  }


  updateMe(data: Partial<AppAuth>) {
    return trackProgress(
      apiClient.put<User>(`${USER_PATH_API}/updateMe`, data),
      `PUT_${USER_PATH_API}updateMe`
    ).then((r) => new User(r.data));
  }

  // suppression de mon compte via mon id
  deleteMe() {
    return trackProgress(
      apiClient.delete(`${USER_PATH_API}/deleteMe`),
      `DELETE_${USER_PATH_API}deleteMe`
    );
  }
}

const userApi = new UserApi()

export default userApi;