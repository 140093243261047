import { Link, Outlet } from "react-router-dom";
import { ROUTES } from "../../router";
import "./WelcomeLayout.css";
import useGtagPageTracking from "../../../useGtagPageTracking";

const WelcomeLayout = () => {
  const d = new Date().getFullYear();
  useGtagPageTracking(); // Appel du hook à l'intérieur du composant enfant du Router
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 h-screen max-h-screen">
        <div className="bg-gray-50 h-full overflow-y-auto">
          <div className="p-frame flex h-full items-center justify-center">
            <div>
              <Outlet />
                <div className="mt-8">
                  <div className='flex space-x-4 font-antic text-sm'>
                    <Link
                      className="footer-link"
                      to={ROUTES.LEGAL.CHART_OF_CONFIDENTIALITY}
                    >
                      Charte de confidentialité
                    </Link>
                    <Link
                      className="footer-link"
                      to={ROUTES.LEGAL.TERMS_OF_USE}
                    >
                      Mentions légales
                    </Link>
                    <Link
                      className="footer-link"
                      to={ROUTES.LEGAL.TERMS_AND_CONDITIONS}
                    >
                      Conditions Générales de Ventes
                    </Link>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div className="relative h-full flex flex-col justify-between p-frame hidden md:block">
          <img
            alt="background"
            src="/images/bg.jpg"
            className="hidden md:block object-cover w-full h-full"
            loading="lazy" // Lazy loading pour améliorer la performance
          />
          <span className="text-white absolute bottom-4 md:bottom-20 right-4 md:right-20">
            LolaBeauty copyright {d}
          </span>
        </div>
      </div>
    </>
  );
};

export default WelcomeLayout;
