import { Button } from "antd";
import ModalConfirm from "../common/components/Modals/modal-confirm.component";
import PageCanvas from "../common/components/Panels/PageCanvas";
import { useCart } from "../common/contexts/CartContext";
import { useAppDispatch } from "../common/hooks";
import { showSuccessNotification } from "../common/services/notificationService";
import { openModalAction } from "../common/state/modal.slice";
import Cart from "./Cart";

const CartPage = () => {
  const {cart, deleteAllFromCart, fetchCart } = useCart();
  const dispatch = useAppDispatch();

  const handleDelete = () => {
    dispatch(
      openModalAction({
        component: (
          <ModalConfirm
            formCb={async (confirm) => {
              if (confirm === true) {
                await deleteAllFromCart();
                await fetchCart();
                
                showSuccessNotification("Votre panier à été vidé");
              }
            }}
            confirmIsDanger
            body="Êtes vous sur de vouloir vider tout votre panier ?"
          />
        ),
        options: { title: "Vider le panier" },
      })
    );
  };

  return (
    <>
      <PageCanvas title="Prise de rendez-vous">
        <PageCanvas.Actions>
          {
            cart.rows.length > 0 && (
              <Button type="primary" onClick={handleDelete}>
                Vider le panier
              </Button>
            )
          }
        </PageCanvas.Actions>
        <PageCanvas.Content>
          <Cart />
        </PageCanvas.Content>
      </PageCanvas>
    </>
  );
};

export default CartPage;
