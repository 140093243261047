// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-link {

    box-sizing: border-box;

    width: -moz-fit-content;

    width: fit-content;

    border-radius: 0.375rem;

    padding: 0.25rem;

    transition-property: all;

    transition-duration: 300ms;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

.footer-link:hover {

    --tw-bg-opacity: 1;

    background-color: rgb(199 157 121 / var(--tw-bg-opacity));

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}
`, "",{"version":3,"sources":["webpack://./src/common/components/Footer/Footer.css"],"names":[],"mappings":"AACE;;IAAA,sBAA8E;;IAA9E,uBAA8E;;IAA9E,kBAA8E;;IAA9E,uBAA8E;;IAA9E,gBAA8E;;IAA9E,wBAA8E;;IAA9E,0BAA8E;;IAA9E;AAA8E;;AAI9E;;IAAA,kBAA+B;;IAA/B,yDAA+B;;IAA/B,oBAA+B;;IAA/B;AAA+B","sourcesContent":[".footer-link {\n  @apply box-border p-1 rounded-md w-fit transition-all duration-300 ease-in-out;\n}\n\n.footer-link:hover {\n  @apply bg-jb-primary text-white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
