import { User } from "../types/modelUser";

type PropsType = {
    user: User,
}

const UserFullName = ({ user }: PropsType) => {

    return (
        <span>{user.firstName?.toString()} {user.lastName?.toString()}</span>
    );
};

export default UserFullName;
