
import { useTranslation } from "react-i18next";
import AskNewPasswordForm from "./AskNewPasswordForm";

const AskNewPasswordPage = () => {
  const { t } = useTranslation();


  return <>
    <h1 className="titleForm">{t('UI.FORGOT_PASSWORD_PAGE.title')}</h1>
    <p>{t('UI.FORGOT_PASSWORD_PAGE.p1')}</p>
    <AskNewPasswordForm></AskNewPasswordForm>
  </>
}

export default AskNewPasswordPage;