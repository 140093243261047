import PageCanvas from "../../common/components/Panels/PageCanvas";
import TermOfUse from "./TermOfUse";

const TermOfUsePage = () => {
  return (
    <>
      <PageCanvas title="Mentions Légales" titleAlignment="center">
        <PageCanvas.Content>
          <TermOfUse />
        </PageCanvas.Content>
      </PageCanvas>
    </>
  );
};

export default TermOfUsePage;
