import React from 'react';
import { Appointment } from '../Appointment/types/modelAppointment';
import { Collapse, Card, Button, Empty } from 'antd';
import { formatDate } from '../Appointment/Calendar/dateUtils';
import StatusBadge from '../Appointment/StatusBadge';
import appointmentApi from '../Appointment/services/appointment.api';
import { AppointmentStatusEnum } from '../Appointment/types/appointmentTypes';
import ModalConfirm from '../common/components/Modals/modal-confirm.component';
import { openModalAction } from '../common/state/modal.slice';
import { useAppDispatch } from '../common/hooks';

interface AppointmentMobileViewProps {
  upcomingAppointments: Appointment[] | undefined;
  pastAppointments: Appointment[] | undefined;
  fetchAppointments: () => void;
}


const AppointmentDashboardClientMobile = (
  { upcomingAppointments, pastAppointments, fetchAppointments }: AppointmentMobileViewProps) => {
  const dispatch = useAppDispatch();

  const openModalCancelAppointment = (appointmentId: string) => {
    return openModalAction({
      component: (
        <ModalConfirm
          formCb={async (confirm) => {
            if (confirm === true) {
              await appointmentApi.cancelAppointment(
                appointmentId,
                AppointmentStatusEnum.cancelled.toString()
              );
              fetchAppointments();
            }
          }}
        />
      ),
      options: { title: "Êtes vous sûr de vouloir annuler ce rendez-vous ?" },
    });
  };

  // Création des items pour le composant Collapse
  const collapseItems = [
    {
      key: '1',
      label: 'Rendez-vous à venir',
      children: (
        upcomingAppointments && upcomingAppointments.length > 0 ? (
          <div
            className="grid grid-cols-1 gap-4"
            style={{ gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))" }}
          >
            {upcomingAppointments.map((appointment:Appointment) => (
              <Card
                key={appointment._id}
                title={appointment.serviceId.name}
                extra={<StatusBadge status={appointment.status} />}
                actions={[
                  <Button
                    onClick={() => dispatch(openModalCancelAppointment(appointment._id!))}
                  >
                    Annuler ce rendez-vous
                  </Button>,
                ]}
              >
                <p>{formatDate(appointment.date)}</p>
              </Card>
            ))}
          </div>
        ) : (
          <Empty className="mt-52" description="Aucun rendez-vous à venir" />
        )
      ),
    },
    {
      key: '2',
      label: 'Rendez-vous archivés',
      children: (
        pastAppointments && pastAppointments.length > 0 ? (
          <div
            className="grid grid-cols-1 gap-4"
            style={{ gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))" }}
          >
            {pastAppointments.map((appointment: Appointment) => (
              <Card
                key={appointment._id}
                title={appointment.serviceId.name}
                extra={<StatusBadge status={appointment.status} />}
                actions={[
                  <Button
                    onClick={() => dispatch(openModalCancelAppointment(appointment._id!))}
                  >
                    Annuler ce rendez-vous
                  </Button>,
                ]}
              >
                <p>{formatDate(appointment.date)}</p>
              </Card>
            ))}
          </div>
        ) : (
          <Empty className="mt-52" description="Aucun rendez-vous archivés" />
        )
      ),
    },
  ];

  return (
    <Collapse items={collapseItems} />
  );
};

export default AppointmentDashboardClientMobile;
