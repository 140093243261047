// cartUtils.ts
import { ICart } from '../types/cartTypes';

interface DepositDetails {
  description: string;
  amount: number;
}

export const calculateDeposit = (cartItems: ICart[]): { totalDeposit: number; details: DepositDetails[] } => {
  let totalDeposit = 0;
  const details = cartItems.map(item => {
    const itemDeposit = Number(item.serviceId.price) > 100 ? 50 : 20;
    totalDeposit += itemDeposit;
    return { description: item.serviceId.name, amount: itemDeposit };
  });
  return { totalDeposit, details };
};