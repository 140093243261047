
import VerifyEmail from './VerifyEmail';

const VerifyEmailPage = () => {
  return <>
    <h1 className="titleForm">Vérification de l'email</h1>
    <VerifyEmail></VerifyEmail>
  </>
}

export default VerifyEmailPage;