import React, { ReactNode } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Badge } from "antd";
import tailwindTheme from "../../../../tailwind-theme";

interface SidebarLinkType {
  link: {
    id: string;
    label: string;
    route?: string;
    icon: ReactNode;
    badge?: number;
    onClick?: () => void;
  };
}

const SideNavLink: React.FC<SidebarLinkType> = ({ link }) => {
  const location = useLocation();

  // Determine if the current link is active
  const isActive = location.pathname === (link.route || "/app");

  const linkClasses = `nav-link flex items-center justify-between ${isActive ? 'active' : ''}`;

  const linkContent = (
    <NavLink
      to={link.route || "/app"}
      className={linkClasses}
      onClick={link.onClick}
    >
      <div className="flex items-center space-x-3">
        <span className="icon">{link.icon}</span>
        <span className="link-label">{link.label}</span>
      </div>
      {link.badge && link.id && (
        <Badge
          showZero={false}
          className={`text-xs px-2 py-1 ${isActive && link.id === "cart" ? "text-jb-primary" : "text-white"
            }`}
          style={{
            color: isActive && link.id === "cart" ? tailwindTheme.colors.jb.primary : "white",
            backgroundColor: isActive && link.id === "cart" ? "white" : tailwindTheme.colors.jb.primary,
          }}
          count={link.badge > 9 ? "9+" : link.badge}
        />
      )}
    </NavLink>
  );

  return linkContent;
 
};

export default SideNavLink;
