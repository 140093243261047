
import React from "react";

const InvoiceSvg = ({ color = 'black' }) => {
  return (
    <svg width="27" height="27" fill="none" viewBox="0 0 27 27"  xmlns="http://www.w3.org/2000/svg">
      <path d="M2.24976 12.3749V19.1249C2.24976 23.6249 3.37476 24.7499 7.87476 24.7499H19.1248C23.6248 24.7499 24.7498 23.6249 24.7498 19.1249V7.87488C24.7498 3.37488 23.6248 2.24988 19.1248 2.24988H17.4373C15.7498 2.24988 15.3785 2.74488 14.7373 3.59988L13.0498 5.84988C12.6223 6.41238 12.3748 6.74988 11.2498 6.74988H7.87476C3.37476 6.74988 2.24976 7.87488 2.24976 12.3749Z" stroke={color} strokeWidth="1.1" strokeMiterlimit="10" />
      <path d="M17.9998 2.24988H7.87476C5.62476 2.24988 4.49976 3.37488 4.49976 5.62488V7.17738" stroke={color} strokeWidth="1.1" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 15L13 18L10 21" stroke={color} strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4 18H13" stroke={color} strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
};

export default InvoiceSvg;
