import { MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, Table } from "antd";
import React, { useEffect, useState } from "react";
import ModalConfirm from "../../common/components/Modals/modal-confirm.component";
import Fieldset from "../../common/components/Panels/Fieldset";
import { useRefresh } from "../../common/contexts/RefreshContext";
import { useAppDispatch } from "../../common/hooks";
import { ApiListResult } from "../../common/services/types";
import { openModalAction } from "../../common/state/modal.slice";
import { Service } from "../../Services/services/types/modelService";
import adminApi from "../services/admin.api";
import {
  getServiceMenuItems,
  MenuItemContactKeysEnum,
} from "./AdminListServicesPage.misc";
import ServiceForm from "./Forms/ServiceForm";


const AdminListServices: React.FC = () => {
  const { refreshKey, triggerRefresh } = useRefresh();  // Utiliser le contexte de rafraîchissement

  const [services, setServices] = useState<ApiListResult<Service>>();

  const dispatch = useAppDispatch();

  const fetchServiceAdmin = async () => {
    adminApi
      .findServicesAdmin({}, 1, 10)
      .then((res) => setServices(res))
      .catch(console.log);
  };

  useEffect(() => {
    fetchServiceAdmin();
  }, [refreshKey]);  // Re-fetch lorsque `refreshKey` change


  const openModalEditService = (service: Service) => {
    dispatch(
      openModalAction({
        component: <ServiceForm service={service} formCb={
          () => triggerRefresh()
        } />,
        options: { title: "Modifier un service" },
      })
    );
  };

  const openModalDeleteService = (service: Service) => {
    dispatch(
      openModalAction({
        component: (
          <ModalConfirm
            formCb={async (confirm) => {
              if (confirm === true) {
                await adminApi.deleteService(service._id!);
                fetchServiceAdmin();
              }
            }}
            confirmIsDanger
            body="Êtes vous sur de vouloir supprimer les contacts selectionnés ?"
          />
        ),
        options: { title: "Supprimer un contact" },
      })
    );
  };

  const itemClicked = (service: Service, infos: any) => {
    switch (infos.key) {
      case MenuItemContactKeysEnum.editService:
        openModalEditService(service);
        break;
      case MenuItemContactKeysEnum.deleteService:
        openModalDeleteService(service)
        break;

      default:
        break;
    }
  };

  const columns: any = [
    {
      title: "Image",
      dataIndex: "backgroundImage",
      key: "backgroundImage",
      render: (backgroundImage: string | undefined) => (
        <img
          src={backgroundImage}
          alt="backgroundImage"
          style={{ width: "100px", height: "100px" }}
          loading="lazy"
        />
      ),
    },
    {
      title: "Nom",
      dataIndex: "name",
      key: "name",
      render: (name: string, service: any) => (
        <Button type="text"
          onClick={() => {
            openModalEditService(service);
          }}
        >
          {name}
        </Button>
      ),
    },
    {
      title: <div className="text-center">Options</div>,
      key: "actions",
      render: (text: string, service: any) => (
        <div className="text-center">
          <Dropdown menu={{ items: getServiceMenuItems(service, itemClicked) }}>
            <MoreOutlined />
          </Dropdown>
        </div>
      ),
    },
  ];


  return (
    <Fieldset>
      <Table
        rowKey={"_id"} // Ensure the unique identifier is correctly used
        columns={columns}
        scroll={{ x: "max-content" }}
        pagination={{
          hideOnSinglePage: true,
          total: services?.count,
          pageSize: 3,
        }}
        dataSource={services?.rows.map((r) => ({
          key: r._id,  // Ensure key prop is unique
          ...r,
        })) || []}
      />
    </Fieldset>
  );
};
export default AdminListServices;
