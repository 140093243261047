import { Collapse } from 'antd';
import 'antd/dist/reset.css'; // Import the Ant Design styles
import React from 'react';


const { Panel } = Collapse;
const CustomPanelHeader: React.FC<{ title: string }> = ({ title }) => (
  <span className="font-bold">{title}</span>
);

const ChartOfConfidentialitySections: React.FC<{ sections: { title: string; content: string[] }[] }> = ({ sections }) => {
  return (
    <div className="container mx-auto p-4">
      <Collapse
        // tous doit être ouvert par défaut
        defaultActiveKey={[
          // mapper la propriété id de chaque section
          // ...sections.map((section) => section.id),

        ]}
        expandIconPosition="right"
        className="w-full"
      >
        {sections.map((section, index) => (
          <Panel header={<CustomPanelHeader title={section.title} />} key={index} >{section.content.map((paragraph, i) => (
            <p key={i} >
              {paragraph}
            </p>
          ))}
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};


export default ChartOfConfidentialitySections;
