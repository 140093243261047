import { Button } from "antd";
import { useAppDispatch } from "../../common/hooks";
import { User } from "../../User/types/modelUser";
import { openDrawerAction } from "../../common/state/modal.slice";
import UserForm from "./Forms/UserForm";
import UserFullName from "../../User/components/UserFullName";

type UserResumePropsType = {
  /**
   * L'utilisateur dont le résumé sera affiché.
   */
  user: User;

  /**
   * Indique si le résumé de l'utilisateur est modifiable.
   */
  editable?: boolean;
};

/**
 * Composant qui affiche un résumé de l'utilisateur avec la possibilité de modifier les informations.
 * @param {UserResumePropsType} props - Les propriétés du composant.
 * @returns {JSX.Element} - Le composant résumé de l'utilisateur.
 */
const UserResume = ({ user }: UserResumePropsType) => {
  const dispatch = useAppDispatch();

  /**
   * Ouvre le modal pour modifier les informations du contact utilisateur.
   * @param {User} user - L'utilisateur dont les informations doivent être modifiées.
   */
  const openModalContact = (user: User) => {
    dispatch(
      openDrawerAction({
        component: <UserForm user={user} />,
        options: { title: "Modifier un contact" },
      })
    );
  };

  return (
    <div>
      <div>
        <Button type="text" onClick={() => openModalContact(user)}>
          <UserFullName user={user} />
        </Button>
      </div>
    </div>
  );
};

export default UserResume;
