
import { useTranslation } from "react-i18next";
import RegisterForm from './RegisterForm';

const RegisterPage = () => {
  const { t } = useTranslation();
  

  return <>
    <h1 className="titleForm">{t('UI.REGISTER_PAGE.title')}</h1>
    <RegisterForm></RegisterForm>
  </>
}

export default RegisterPage;