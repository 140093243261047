import PageCanvas from "../../common/components/Panels/PageCanvas";
import ChartOfConfidentiality from "./ChartOfConfidentiality";

const ChartOfConfidentialityPage = () => {
  return (
    <>
      <PageCanvas title="Charte de Confidentialité" titleAlignment="center">
        <PageCanvas.Content>
          <ChartOfConfidentiality />
        </PageCanvas.Content>
      </PageCanvas>
    </>
  );
};

export default ChartOfConfidentialityPage;
