import { Button, Empty, Result } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import ModalConfirm from "../../common/components/Modals/modal-confirm.component";
import { useAppDispatch } from "../../common/hooks";
import { ApiListResult } from "../../common/services/types";
import { openModalAction } from "../../common/state/modal.slice";
import { TimelineStatusEnum } from "./types/LogTypes";
import { Log } from "./types/modelLog";
import TypeTimelineItem from "./TypeTimelineItem";
import logApi from "./services/log.api";

const Logs = () => {
  const [logs, setLogs] = useState<ApiListResult<Log>>();
  const dispatch = useAppDispatch();
  const fetchLogs = async () => {
    logApi
      .findLogs({}, 1, 10)
      .then((res) => setLogs(res))
      .then((res) => console.log(res))
      .catch(console.log);
  };

  useEffect(() => {
    fetchLogs();
  }, []);

  const handleDeleteAppointment = async (log: Log) => {
    try {
      await logApi.deleteLogAndAppointment(log._id!);
      fetchLogs();
    } catch (error) {
      console.log(error);
    }
  };

  const openModalDeleteAppointment = (log: Log) => {
    return openModalAction({
      component: (
        <ModalConfirm
          formCb={async (confirm) => {
            if (confirm === true) {
              await handleDeleteAppointment(log);
            }
          }}
          body={
            <div>
              <p>
                Vous êtes sur le point de libérer le créneau du{" "}
                {dayjs(log.appointmentId.date).format("dddd D MMMM YYYY")} à{" "}
                {dayjs(log.appointmentId.date).format("HH:mm")}
              </p>
              <p>
                Service : <strong>{log.serviceId.name}</strong>
              </p>
            </div>
          }
        />
      ),
      options: {
        title: "Voulez-vous libérer ce créneau ?",

      },
    });
  };

  const groupLogsByDate = (logs: Log[]) => {
    return logs.reduce((acc, log) => {
      const date = dayjs(log.timestamp).format("D MMMM YYYY");
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(log);
      return acc;
    }, {} as Record<string, Log[]>);
  };

  const groupedLogs = logs ? groupLogsByDate(logs.rows) : {};

  return (
    <div className="flex flex-col space-y-2 p-4 overflow-y-scroll max-h-[700px]">      {logs && logs.rows && logs.rows.length > 0 ? (
      Object.entries(groupedLogs).map(([date, logsForDate]) => (
        <div
          key={date}
          className="p-5 border border-gray-100 rounded-lg shadow-md"
        >
          <time className="text-lg font-semibold text-gray-900">{date}</time>
          <ol className="divide-y divide-gray-200">
            {logsForDate.map((log) => (
              <li
                key={log._id}
                className="p-3 flex flex-col md:flex-row md:items-center hover:bg-gray-100 rounded-md transition-all duration-200"
              >
                <TypeTimelineItem typeAction={log.typeAction} />
                <div className="text-gray-600 mt-2 md:mt-0 md:ml-4 flex flex-col md:flex-row md:justify-between md:items-center">
                  <div className="flex-1">
                    <div className="text-base font-normal">
                      <span className="font-medium text-gray-900">
                        {log.descriptionAction}
                      </span>
                    </div>
                    <div className="text-sm font-normal md:mt-0">
                      <span className="font-medium text-gray-900">
                        {log.serviceId.name}
                      </span>
                    </div>
                  </div>
                  {log.typeAction === TimelineStatusEnum.delete && (
                    <Button
                      type="primary"
                      className="sm:mt-2 md:mt-0 xl:mt-0 md:w-auto"
                      disabled={log.appointmentId.date < new Date()}
                      onClick={() =>
                        dispatch(openModalDeleteAppointment(log))
                      }
                    >
                      Libérer le créneau
                    </Button>
                  )}
                </div>
              </li>
            ))}
          </ol>
        </div>
      ))
    ) : (
      <Result
        className="mt-20"
        icon={<Empty description={""} />}
        title="Aucun logs à afficher."
      />
    )}
    </div>
  );
};

export default Logs;
