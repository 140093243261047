// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ======== ANTD ======== */
.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    --tw-border-opacity: 1;
    border-color: rgb(199 157 121 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(199 157 121 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.ant-btn-jb-primary {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(199 157 121 / var(--tw-bg-opacity)) !important;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

/* lbel */
.ant-form-item .ant-form-item-label > label {
    font-weight: 500;
    font-family: Antic Didone, serif;
    font-size: 1rem;
    line-height: 1.5rem
}

.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-thead > tr > td {
    font-family: Antic Didone, serif;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600
}

.ant-badge .ant-badge-count {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.ant-modal .ant-modal-title {
    font-family: Yusei Magic, sans-serif
}


`, "",{"version":3,"sources":["webpack://./src/antd.css"],"names":[],"mappings":"AAAA,2BAA2B;AAEzB;IAAA,sBAAiD;IAAjD,yDAAiD;IAAjD,kBAAiD;IAAjD,yDAAiD;IAAjD,oBAAiD;IAAjD;AAAiD;;AAIjD;IAAA,6BAA4C;IAA5C,oEAA4C;IAA5C,oBAA4C;IAA5C,gDAA4C;IAA5C,sBAA4C;IAA5C,8BAA4C;IAA5C;AAA4C;;AAG9C,SAAS;AAEP;IAAA,gBAAkB;IAClB,gCAAiB;IACjB,eAAgB;IAAhB;AAFkB;;AAOlB;;IAAA,gCAAiB;IACjB,eAAgB;IAAhB,mBAAgB;IAChB;AAFiB;;AAMjB;IAAA,sBAAkB;IAAlB,8BAAkB;IAAlB;AAAkB;;AAIlB;IAAA;AAAiB","sourcesContent":["/* ======== ANTD ======== */\n.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {\n  @apply text-white bg-jb-primary border-jb-primary;\n}\n\n.ant-btn-jb-primary {\n  @apply !bg-jb-primary text-white shadow-none;\n}\n\n/* lbel */\n.ant-form-item .ant-form-item-label > label {\n  @apply font-medium;\n  @apply font-antic;\n  @apply text-base;\n}\n\n.ant-table-wrapper .ant-table-thead > tr > th,\n.ant-table-wrapper .ant-table-thead > tr > td {\n  @apply font-antic;\n  @apply text-base;\n  @apply font-semibold;\n}\n\n.ant-badge .ant-badge-count {\n  @apply shadow-none;\n}\n\n.ant-modal .ant-modal-title {\n  @apply font-yusei;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
