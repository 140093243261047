import { CalendarOutlined, ClockCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button } from "antd";
import dayjs from "dayjs";
import { useState, useEffect } from "react";
import { useCart } from "../common/contexts/CartContext";
import { useAppDispatch } from "../common/hooks";
import { openModalAction } from "../common/state/modal.slice";
import { Service } from "../Services/services/types/modelService";
import cartApi from "./services/cart.api";
import { Cart } from "./types/modelCart";
import Calendar from "../Appointment/Calendar/Calendar";

type CartItemProps = {
  cart: Cart;
  deleteCart: () => void;
  fetchCart: () => void;
};

const CartItem = ({ cart, deleteCart }: CartItemProps) => {
  const dispatch = useAppDispatch();
  const { fetchCart } = useCart();

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedDateTime, setSelectedDateTime] = useState<Date>();

  useEffect(() => {
    if (selectedDateTime) {
      cartApi
        .updateCartDate(cart._id!, selectedDateTime)
        .then(() => {
          fetchCart();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedDateTime, cart._id, fetchCart]);

  const openNewSubscriptionFormModal = (service: Service) => {
    dispatch(
      openModalAction({
        component: (
          <Calendar
            onDateSelect={setSelectedDate}
            onDateTimeSelect={setSelectedDateTime}
            service={service}
            cart={cart}
            key={cart._id}
          />
        ),
        options: {
          title: "Quand souhaitez-vous prendre rendez-vous ?",
          width: 800,
          maskClosable: true,
          footer: null,
          style: {
            height: "80vh",
          },
        },
      })
    );
  };

  return (
    <div className="flex flex-col md:flex-row shadow-lg rounded-lg overflow-hidden text-black font-yusei mb-6">
      {/* Image en haut pour mobile, à gauche pour desktop */}
      <div className="w-full md:w-1/3 h-32 md:h-44">
        <img
          src={cart.serviceId.backgroundImage}
          alt={cart.serviceId.name}
          className="w-full h-full object-cover object-center"
          loading="lazy"
        />
      </div>

      {/* Informations en dessous pour mobile, à droite pour desktop */}
      <div className="p-6 flex flex-col justify-between w-full md:w-2/3">
        <div>
          <h3 className="text-2xl font-bold mb-2">{cart.serviceId.name}</h3>
          {(selectedDateTime || cart.date) && (
            <div className="text-sm mb-4">
              <CalendarOutlined className="mr-1" />
              {dayjs(selectedDate || cart.date).format("dddd DD MMMM YYYY")}
              <br />
              <ClockCircleOutlined className="mr-1" />
              {dayjs(selectedDateTime || cart.date).format("HH:mm")}
            </div>
          )}
        </div>

        {/* Boutons d'actions */}
        <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4">
          <Button
            onClick={() => deleteCart()}
            icon={<DeleteOutlined />}
            className="w-full lg:w-auto"
            type="primary"
            danger
          >
            Supprimer
          </Button>
          <Button
            className={`w-full md:w-auto ${!cart.date ? "animate-pulse border-jb-primary" : "bg-jb-primary text-white"}`}
            onClick={() => openNewSubscriptionFormModal(cart.serviceId)}
          >
            {!cart.date ? "Choisir un créneau" : "Modifier le rendez-vous"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
