import { IService } from "../../Services/services/types/serviceTypes";
import { ICart } from "./cartTypes";

export class Cart implements ICart {
  userId!: string;
  serviceId!: IService;
  date!: Date;

  id?: string;
  _id?: string;
  updatedAt?: Date;
  createdAt?: Date;

  constructor(cart: ICart) {
    Object.assign(this, {
      ...cart
      // date: new Date(cart.createdAt!),
    });
  }
}
