import React from "react";

const ServiceSvg = ({ color = "black" }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.995 26.7626C27.995 31.0769 23.9749 35.6854 19.8567 35.6854C15.7384 35.6854 11.8163 31.0769 11.8163 26.7626C11.8163 22.4483 18.68 18.0359 19.8567 18.0359C21.0333 18.0359 27.995 22.4483 27.995 26.7626Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8163 26.7626C11.8163 26.7626 5.44287 19.2125 9.16888 14.8982C12.8949 10.5839 21.7196 8.4267 21.7196 8.4267"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.58055 15.7807C8.58055 15.7807 9.75719 3.52406 18.68 4.01433C23.1904 4.30848 24.7593 6.46564 25.3476 8.52475C25.5564 9.54462 25.5042 10.6007 25.1956 11.595C24.887 12.5892 24.3322 13.4894 23.5826 14.2118L19.8566 18.0359"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.4457 9.01502C25.4457 9.01502 32.9957 11.4663 32.9957 17.2515C32.8977 21.2716 27.995 26.7626 27.995 26.7626"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ServiceSvg;
