import PageCanvas from "../common/components/Panels/PageCanvas";
import Services from "./Services";

const ServicesPage = () => {
  return (
    <>
      <PageCanvas title="Nos Services">
        <PageCanvas.Content>
          <Services />
        </PageCanvas.Content>
      </PageCanvas>
    </>
  );
};

export default ServicesPage;
