/* ======== CONSTANTES ======== */


/* ======== TYPES ======== */


export const APPOINTMENT_SERVICE = "appointmentService";
// eslint-disable-next-line
export type APPOINTMENT_SERVICE = typeof APPOINTMENT_SERVICE; // Typescript line

/* ======== PAYLOADS ======== */

export type CreateEventPayload = {
  name: string;
};

export type UpdateEventPayload = {
  name?: string;
};


