import { applyMiddleware, createStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import createRootReducer from './rootReducer';
import rootSaga from './rootSaga';
import { composeWithDevTools } from '@redux-devtools/extension';

import { isDev } from '../../config';
import { AUTH_SERVICE } from '../../Auth/state/types';

const persistedReducer = persistReducer(
  {
    key: 'root',
    storage,
    version: 1,
    // transforms: [SetTransform],
    whitelist: [
      AUTH_SERVICE,
    ],
    debug: isDev,
  },
  createRootReducer()
);

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);
sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
