// src/dayjsConfig.ts
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import localeData from 'dayjs/plugin/localeData';
import 'dayjs/locale/fr';

dayjs.extend(localizedFormat);
dayjs.extend(localeData);
dayjs.locale('fr');

export default dayjs;
