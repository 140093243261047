import {
  DeleteOutlined,
  EditOutlined
} from "@ant-design/icons";
import { MenuProps } from "antd";
import { User } from "../../User/types/modelUser";

/**
 * Enumération des actions possibles sur un contact utilisateur.
 */
export enum MenuItemContactKeysEnum {
  /**
   * Action pour modifier un utilisateur.
   */
  editUser = "editUser",

  /**
   * Action pour supprimer un utilisateur.
   */
  deleteUser = "deleteUser",
}

/**
 * Génère les éléments de menu pour les actions sur un utilisateur.
 *
 * @param {User} user - L'utilisateur cible des actions.
 * @param {Function} onItemClick - Fonction de rappel à appeler lors du clic sur un élément de menu.
 * @returns {MenuProps["items"]} - Les éléments de menu configurés pour l'utilisateur.
 */
export const getUserMenuItems = (
  user: User,
  onItemClick: (user: User, infos: any) => void
): MenuProps["items"] => [
  {
    key: '1',
    type: 'group',
    label: 'Actions',
    children: [
      {
        label: "Modifier",
        key: MenuItemContactKeysEnum.editUser,
        icon: <EditOutlined />,
        onClick: (infos: any) => onItemClick(user, infos),
      },
      {
        label: "Supprimer",
        key: MenuItemContactKeysEnum.deleteUser,
        icon: <DeleteOutlined />,
        onClick: (infos: any) => onItemClick(user, infos),
      },
    ],
  },
];
