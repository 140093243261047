import { combineReducers } from 'redux';
import appointmentSlice from '../../Appointment/state/appointment.slice';
import { APPOINTMENT_SERVICE } from '../../Appointment/state/types';
import authSlice from '../../Auth/state/auth.slice';
import { AUTH_SERVICE } from '../../Auth/state/types';
import modalSlice from '../state/modal.slice';
import { MODAL_SERVICE } from '../state/types';

const rootReducer = combineReducers({
  [MODAL_SERVICE]: modalSlice,
  [AUTH_SERVICE]: authSlice,
  [APPOINTMENT_SERVICE]: appointmentSlice,
});

export type RootState = ReturnType<typeof rootReducer>;

export default function createRootReducer(): (state: RootState | undefined, action: any) => RootState {
  return (state, action) => rootReducer(state, action);
}
