import { IdType, TokenType } from "../../common/store/types";
import { UserRolesEnum } from "../../User/types/UserTypes";

/* ======== CONSTANTES ======== */

/* ======== TYPES ======== */

export type AuthUserType<K> = K & {
  id: IdType;
  email: string;
  firstname: string;
  lastName: string;
  mobile: string;
  role: UserRolesEnum;
};  

export type AuthStateType<K> = {
  token: TokenType,
  user: AuthUserType<K>
}

export const AUTH_SERVICE = "authService";
// eslint-disable-next-line
export type AUTH_SERVICE = typeof AUTH_SERVICE; // Typescript line

/* ======== PAYLOADS ======== */
export type LoginPayload = {
  email: string;
  password: string;
};

export type RegisterPayload = {
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  password: string;
};
