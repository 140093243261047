import { defaultPagination } from "../../common/app-constants";
import ApiService from "../../common/services/apiService";
import { Service } from "./types/modelService";
import { IService } from "./types/serviceTypes";

const SERVICES_PATH_API = "services";

class ServiceApi extends ApiService<IService> {
  constructor() {
    super(SERVICES_PATH_API);
  }

  findServices = (
    query = {},
    page = defaultPagination.page,
    pageSize = defaultPagination.pageSize
  ): Promise<{ count: number; rows: Service[] }> => {
    return this.find({ query, page, pageSize }).then((res) => {
      return {
        count: res.count,
        rows: res.rows.map((r) => new Service(r)),
      };
    });
  };
}

const serviceApi = new ServiceApi();

export default serviceApi;
