import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../common/store';
import { Appointment } from '../types/modelAppointment';
import { APPOINTMENT_SERVICE } from './types';

// to implement

type AppointmentServiceStateType = {
    appointment: Appointment | null;
};

const appointmentInitialState: AppointmentServiceStateType = {
    appointment: null,
};

export const appointmentSlice = createSlice({
    name: APPOINTMENT_SERVICE,
    initialState: appointmentInitialState,
    reducers: {
        getAppointmentAction: (state: AppointmentServiceStateType, { payload: _id }: PayloadAction<string>) => {
            state.appointment = null;
        },

        refreshAppointmentAction: (_state: AppointmentServiceStateType) => {
            // state.appointment = null;
        },

        setAppointmentAction: (state: AppointmentServiceStateType, { payload: appointment }: PayloadAction<Appointment>) => {
            state.appointment = appointment;
        },
        unsetAppointmentAction: (state: AppointmentServiceStateType, _payload: PayloadAction) => {
            state.appointment = null;
        },
    },
});

export const {
    getAppointmentAction,
    refreshAppointmentAction,
    setAppointmentAction,
    unsetAppointmentAction,
} = appointmentSlice.actions;

export default appointmentSlice.reducer;

export const selectAppointmentService = (state: RootState) => state.appointmentService;
export const selectCurrentAppointment = createSelector(selectAppointmentService, (appointmentSvc) => appointmentSvc.appointment);
