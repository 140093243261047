import { IAppointment } from "../../Appointment/types/appointmentTypes";
import { Appointment } from "../../Appointment/types/modelAppointment";
import { defaultPagination } from "../../common/app-constants";
import ApiService from "../../common/services/apiService";
import { ApiListResult } from "../../common/services/types";

const ESTH_PATH_API = "esth/";

class EsthApi extends ApiService<IAppointment> {
  constructor() {
    super(ESTH_PATH_API);
  }

  // custom method for admin/appointments
  findAppointmentsEsth = (
    query = {},
    page = defaultPagination.page,
    pageSize = defaultPagination.pageSize
  ): Promise<{ count: number; rows: Appointment[] }> => {
    return this.custom<ApiListResult<Appointment>>("get", `appointments`, {
      query,
      page,
      pageSize,
    }).then((res) => {
      return {
        count: res.count,
        rows: res.rows.map((r) => new Appointment(r)),
      };
    });
  };
}

const esthApi = new EsthApi();

export default esthApi;
