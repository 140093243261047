import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import cartApi from '../../Cart/services/cart.api';
import { ICart } from '../../Cart/types/cartTypes';
import { IService } from '../../Services/services/types/serviceTypes';
import { ApiListResult } from '../services/types';
import { showErrorNotification, showSuccessNotification } from '../services/notificationService';
import { calculateDeposit } from '../../Cart/utils/cartUtils';
import { Service } from '../../Services/services/types/modelService';
import { useAppSelector } from '../hooks'; // Importez votre hook pour accéder à l'état d'authentification

interface CartContextType {
  cart: ApiListResult<ICart>;
  fetchCart: () => Promise<void>;
  addToCart: (service: IService, date: Date) => Promise<void>;
  deleteAllFromCart: () => Promise<void>;
  deleteFromCart: (cartId: string) => Promise<void>;
  makePayment: (cart: ApiListResult<ICart>) => Promise<void>;
  deposit: number;
  depositDetails: { description: string; amount: number }[];
}

const CartContext = createContext<CartContextType | undefined>(undefined);

interface CartProviderProps {
  children: ReactNode;
}

export const CartProvider = ({ children }: CartProviderProps) => {
  const isAuthenticated = useAppSelector((state) => state.authService.auth); // Récupération de l'état d'authentification
  const [cartList, setCartList] = useState<ApiListResult<ICart>>({ count: 0, rows: [] });
  const [deposit, setDeposit] = useState<number>(0);
  const [depositDetails, setDepositDetails] = useState<{ description: string; amount: number }[]>([]);

  const fetchCart = useCallback(async () => {
    if (!isAuthenticated) return; // Si l'utilisateur n'est pas connecté, ne pas faire de requête API
    try {
      const res = await cartApi.fetchCart({}, 1, 10);
      setCartList(res);
      const { totalDeposit, details } = calculateDeposit(res.rows);
      setDeposit(totalDeposit);
      setDepositDetails(details);
    } catch (error) {
      console.error(error);
    }
  }, [isAuthenticated]); // On dépend de l'état isAuthenticated
  
  const addToCart = useCallback(async (service: Service, date: Date) => {
    if (!isAuthenticated) {
      showErrorNotification('Veuillez vous connecter pour ajouter un service au panier');
      return;
    }
    try {
      await cartApi.addServiceToCart(service._id!, date);
      await fetchCart();
      showSuccessNotification('Service ajouté au panier');
    } catch (e) {
      showErrorNotification('Déjà ajouté au panier');
    }
  }, [fetchCart, isAuthenticated]);

  const deleteAllFromCart = useCallback(async () => {
    if (!isAuthenticated) return;
    try {
      await cartApi.removeAllFromCart();
      await fetchCart();
    } catch (error) {
      console.error(error);
    }
  }, [fetchCart, isAuthenticated]);

  const deleteFromCart = useCallback(async (cartId: string) => {
    if (!isAuthenticated) return;
    try {
      await cartApi.removeFromCart(cartId);
      await fetchCart();
    } catch (error) {
      console.error(error);
    }
  }, [fetchCart, isAuthenticated]);

  const makePayment = useCallback(async (cartData: ApiListResult<ICart>) => {
    if (!isAuthenticated) return;
    try {
      await cartApi.makePayment(cartData);
    } catch (error) {
      console.error(error);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    fetchCart(); // Appeler fetchCart si l'utilisateur est connecté
  }, [fetchCart]);

  return (
    <CartContext.Provider
      value={{
        cart: cartList,
        fetchCart,
        addToCart,
        deleteAllFromCart,
        deleteFromCart,
        makePayment,
        deposit,
        depositDetails,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = (): CartContextType => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};
