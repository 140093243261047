import { IService } from "./serviceTypes";

export class Service implements IService {
  name!: string;
  backgroundImage?: string;
  backgroundImagePublicId?: string;
  linkForDescription?: string;
  price!: number | string;
  duration!: number;

  // options!: {
  //   optionTitle: string;
  //   optionType: {
  //     name: string;
  //     price: number | string;
  //     duration: number | string;
  //     brand: string;
  //   };
  // };
  id?: string;
  _id?: string;
  updatedAt?: Date;
  createdAt?: Date;

  constructor(service: IService) {
    Object.assign(this, service);
  }
}

export interface IServiceRef {
  serviceId: IService["_id"];
}
