import { ShoppingCartOutlined } from "@ant-design/icons";
import { Button, Collapse } from "antd";
import React from "react";
import { ICart } from "./types/cartTypes";
import { ApiListResult } from "../common/services/types";

const { Panel } = Collapse;
type CartSummaryProps = {
  totalServicePrice: number;
  deposit: number;
  totalAfterDeposit: number;
  makePayment: () => void;
  depositDetails: { description: string; amount: number }[];
  currentItems: ApiListResult<ICart>; // Add currentItems prop
};

const CartSummary: React.FC<CartSummaryProps> = ({
  totalServicePrice,
  deposit,
  totalAfterDeposit,
  makePayment,
  depositDetails,
  currentItems,
}) => {
  const allItemsHaveDate = currentItems.rows.every((item) => item.date); // Check if all items have a date
  return (
    <div className="sm:w-full md:w-full lg:w-full xl:w-full max-w-sm md:max-w-3xl xl:max-w-sm flex items-start flex-col gap-8 max-xl:mx-auto">
      <div className="p-6 border border-gray-200 rounded-3xl w-full group transition-all duration-500 hover:border-gray-400 ">
        <h2 className="font-bold text-3xl leading-10 text-black pb-6 border-b border-gray-200 ">
          Récapitulatif
        </h2>
        <div className="data py-6 border-b border-gray-200">
          <div className="flex items-center justify-between gap-4 mb-5">
            <p className="font-normal text-lg leading-8 text-gray-400 transition-all duration-500 group-hover:text-gray-700">
              Total des prestations
            </p>
            <p className="font-medium text-lg leading-8 text-gray-900">
              {totalServicePrice.toFixed(2)} €
            </p>
          </div>
          <div className="flex items-center justify-between gap-4 mb-5">
            <p className="font-normal text-lg leading-8 text-gray-400 transition-all duration-500 group-hover:text-gray-700">
              Total des acomptes
            </p>
            <p className="font-medium text-lg leading-8 text-gray-600">
              {deposit.toFixed(2)} €
            </p>
          </div>
          <Collapse className="mb-5" ghost>
            <Panel header="Détails des acomptes" key="2">
              {depositDetails.map((detail, index) => (
                <div
                  key={index}
                  className="flex items-center text-sm justify-between gap-4 mb-2"
                >
                  <p className="font-normal  leading-8 text-gray-500">
                    {detail.description}
                  </p>
                  <p className="font-medium  leading-8 text-gray-900">
                    {detail.amount.toFixed(2)} €
                  </p>
                </div>
              ))}
            </Panel>
          </Collapse>
        </div>
        <div className="total flex items-center justify-between pt-6">
          <p className="font-normal text-xl leading-8 text-black ">
            Reste à payer
          </p>
          <h5 className="font-manrope font-bold text-2xl leading-9 text-indigo-600">
            {totalAfterDeposit.toFixed(2)} €
          </h5>
        </div>
        <div className="flex pt-6">
          <Button
            className="w-full"
            type="primary"
            disabled={!allItemsHaveDate} // Disable button if not all items have a date
            icon={<ShoppingCartOutlined />}
            size="large"
            onClick={makePayment}
          >
            Payer
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CartSummary;
