import { CheckOutlined, ClockCircleOutlined, CloudDownloadOutlined, DeleteOutlined } from "@ant-design/icons";
import { Form, Input, InputNumber, Button, Image, Space } from "antd";
import { useState } from "react";
import uploadImage from "../../../common/helpers/uploadImage";
import { useAppDispatch } from "../../../common/hooks";
import { showSuccessNotification } from "../../../common/services/notificationService";
import { CloseAnyModalAction } from "../../../common/state/modal.slice";
import { Service } from "../../../Services/services/types/modelService";
import adminApi from "../../services/admin.api";

type PropsType = {
  service?: Service;
  formCb?: (service: Service) => void;
};

const ServiceForm = ({ service, formCb }: PropsType) => {
  const [backgroundImage, setBackgroundImage] = useState<File | null>(null); // État pour stocker l'image uploadée
  const [previewUrl, setPreviewUrl] = useState<string | null>(service?.backgroundImage || null); // URL de prévisualisation de l'image
  const [imageToDelete, setImageToDelete] = useState<string | null>(service?.backgroundImagePublicId || null); // Stocke l'ID public de l'image à supprimer
  const dispatch = useAppDispatch();

  const handleUploadProduct = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setBackgroundImage(file); // Met à jour l'image sélectionnée
      setPreviewUrl(URL.createObjectURL(file)); // Crée une URL locale pour l'aperçu
      setImageToDelete(service?.backgroundImagePublicId || null); // Marque l'image précédente pour suppression
    }
  };

  const handleRemoveImage = () => {
    setBackgroundImage(null); // Supprime l'image sélectionnée
    setPreviewUrl(null); // Supprime l'URL de prévisualisation
  };

  const saveService = async (formData: Service) => {
    try {
      const updatedFormData = new FormData();

      // Ajout des autres champs du service au formulaire
      updatedFormData.append("name", formData.name);
      updatedFormData.append("price", formData.price.toString());
      updatedFormData.append("duration", formData.duration.toString());

      // Si une nouvelle image est sélectionnée
      if (backgroundImage) {
        // Upload image vers Cloudinary avec le public_id pour la mise à jour
        const uploadedImage = await uploadImage(backgroundImage);
        updatedFormData.append("backgroundImage", uploadedImage.secure_url);

        // Supprimer l'image précédente si nécessaire
        if (imageToDelete) {
          // Appel à une API pour supprimer l'image précédente si besoin
          await fetch(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME_CLOUDINARY}/image/destroy`, {
            method: 'POST',
            body: JSON.stringify({ public_id: imageToDelete }),
            headers: { 'Content-Type': 'application/json' }
          });
        }
      }

      let response;
      if (service) {
        response = await adminApi.updateServicesAdmin(service._id!, updatedFormData as any);
        showSuccessNotification("Succès", "Service modifié avec succès");
      } else {
        response = await adminApi.createServicesAdmin(updatedFormData as any);
        showSuccessNotification("Succès", "Service créé avec succès");
      }

      dispatch(CloseAnyModalAction());
      formCb && formCb(response);
    } catch (error) {
      console.error("Erreur lors de la sauvegarde du service :", error);
    }
  };

  return (
    <Form<Service>
      name="basic"
      encType="multipart/form-data"
      initialValues={service || {}}
      layout="vertical"
      className="form"
      onFinish={saveService}
    >
      <Form.Item
        label="Nom du service"
        name="name"
        rules={[{ required: true, message: "Veuillez saisir le nom du service" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Tarif du service"
        name="price"
        rules={[{ required: true, message: "Veuillez saisir le tarif du service" }]}
      >
        <InputNumber addonAfter={'€'} style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        label="Durée du service"
        name="duration"
        rules={[{ required: true, message: "Veuillez saisir la durée du service" }]}
      >
        <InputNumber addonAfter={<ClockCircleOutlined />} style={{ width: '100%' }} />
      </Form.Item>

      {previewUrl ? (
        <div className="image-preview-container">
          <Space direction="vertical" style={{ textAlign: 'center' }}>
            <Image
              src={previewUrl}
              alt="Preview"
              style={{ width: "100%", height: "auto", maxHeight: "200px" }}
            />
            <Button
              type="dashed"
              icon={<DeleteOutlined />}
              onClick={handleRemoveImage}
            >
              Supprimer l'image
            </Button>
          </Space>
        </div>
      ) : (
        <label htmlFor="uploadImageInput">
          <div className="p-2 bg-slate-100 border rounded h-32 w-full flex justify-center items-center cursor-pointer">
            <div className="text-slate-500 flex justify-center items-center flex-col gap-2">
              <span className="text-4xl"><CloudDownloadOutlined /></span>
              <p className="text-sm">Upload Product Image</p>
            </div>
            <input type="file" id="uploadImageInput" onChange={handleUploadProduct} className="hidden" />
          </div>
        </label>
      )}

      <Form.Item>
        <Button
          className="btn-void"
          htmlType="submit"
          icon={<CheckOutlined />}
        >
          Enregistrer
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ServiceForm;
