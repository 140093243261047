import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { MenuProps } from "antd";
import { Appointment } from "../../Appointment/types/modelAppointment";

/**
 * Enumération des actions possibles pour gérer les rendez-vous administrateur.
 */
export enum MenuItemContactKeysEnum {
  /**
   * Action pour éditer un rendez-vous.
   */
  editAppointment = 'editAppointment',

  /**
   * Action pour supprimer un rendez-vous.
   */
  deleteAppointment = 'deleteAppointment'
}

/**
 * Génère les éléments de menu pour les actions administrateur sur un rendez-vous.
 * 
 * @param {Appointment} appointment - Le rendez-vous cible des actions.
 * @param {Function} onItemClick - Fonction de rappel appelée lorsque l'on clique sur un élément du menu.
 * @returns {MenuProps['items']} - Les éléments de menu configurés pour les actions sur le rendez-vous.
 */
export const getAdminAppointmentMenuItems = (
  appointment: Appointment, 
  onItemClick: (appointment: Appointment, infos: any) => void
): MenuProps['items'] => ([
  {
    key: '1',
    type: 'group',
    label: 'Actions',
    children: [
      {
        label: 'Modifier',
        key: MenuItemContactKeysEnum.editAppointment,
        icon: <EditOutlined />,
        onClick: (infos: any) => onItemClick(appointment, infos),
      },
      {
        label: 'Supprimer',
        key: MenuItemContactKeysEnum.deleteAppointment,
        icon: <DeleteOutlined />,
        onClick: (infos: any) => onItemClick(appointment, infos),
      },
    ],
  },
]);
