import dayjs from "dayjs";
import cartApi from "../../Cart/services/cart.api";
import { CloseAnyModalAction } from "../../common/state/modal.slice";
import { Appointment } from "../types/modelAppointment";
import { Dispatch } from "react";

// Formatte une date en UTC pour l'affichage
export const formattedUTCDate = (date: Date): string => {
  if (date instanceof Date) {
    return date.toLocaleDateString("fr-FR", {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
      timeZone: "UTC",
    });
  } else {
    return "Date invalide";
  }
};

export const formatDuration = (duration:
  number
): string => {
  if (duration >= 60) {
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;
    return `${hours}h${minutes === 0 ? "" : minutes}`;
  } else {
    return `${duration} min`;
  }
}
// Formatte une date pour l'affichage
export const formatDate = (date: Date): string => {
  return (
    dayjs(date).format("dddd D MMMM YYYY") +
    " à " +
    dayjs(date).format("HH:mm")
  ).replace(/^(.)/, function (match) {
    return match.toUpperCase();
  });
};

// Jours de la semaine en français
export const daysOfWeek = [
  "Dimanche",
  "Lundi",
  "Mardi",
  "Mercredi",
  "Jeudi",
  "Vendredi",
  "Samedi",
];

// Récupère les jours de la semaine à partir d'une date de départ
export const getWeekDays = (startDate: Date): Date[] => {
  const startOfWeek = new Date(
    startDate.setDate(startDate.getDate() - startDate.getDay())
  );
  return Array.from({ length: 7 }, (_, i) => {
    const date = new Date(startOfWeek);
    date.setDate(date.getDate() + i);
    return date;
  });
};

// Passe à la semaine précédente
export const goToPreviousWeek = (currentWeek: Date[]): Date[] => {
  const newStartDate = new Date(currentWeek[0]);
  newStartDate.setDate(newStartDate.getDate() - 7);
  return getWeekDays(newStartDate);
};

// Passe à la semaine suivante
export const goToNextWeek = (currentWeek: Date[]): Date[] => {
  const newStartDate = new Date(currentWeek[6]);
  newStartDate.setDate(newStartDate.getDate() + 1);
  return getWeekDays(newStartDate);
};

// Récupère les heures disponibles pour un jour de la semaine donné
export const getAvailableHours = (dayOfWeek: string, serviceDuration: number): string[] => {
  let lastHour, lastMinute;

  if (serviceDuration === 30) {
    if (
      dayOfWeek === "Lundi" ||
      dayOfWeek === "Mercredi" ||
      dayOfWeek === "Vendredi"
    ) {
      lastHour = 18;
      lastMinute = 30;
    } else if (dayOfWeek === "Samedi") {
      lastHour = 15;
      lastMinute = 30;
    } else if (dayOfWeek === "Jeudi") {
      lastHour = 13;
      lastMinute = 30;
    } else {
      return []; // Fermé
    }
  } else if (serviceDuration === 120) {
    if (
      dayOfWeek === "Lundi" ||
      dayOfWeek === "Mercredi" ||
      dayOfWeek === "Vendredi"
    ) {
      lastHour = 17;
      lastMinute = 0;
    } else if (dayOfWeek === "Samedi") {
      lastHour = 14;
      lastMinute = 0;
    } else if (dayOfWeek === "Jeudi") {
      lastHour = 12;
      lastMinute = 0;
    } else {
      return []; // Fermé
    }
  } else {
    if (
      dayOfWeek === "Lundi" ||
      dayOfWeek === "Mercredi" ||
      dayOfWeek === "Vendredi"
    ) {
      lastHour = 18;
      lastMinute = 0;
    } else if (dayOfWeek === "Samedi") {
      lastHour = 15;
      lastMinute = 0;
    } else if (dayOfWeek === "Jeudi") {
      lastHour = 13;
      lastMinute = 0;
    } else {
      return []; // Fermé
    }
  }

  const hours: string[] = [];
  for (let hour = 10; hour <= lastHour; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      if (hour === lastHour && minute > lastMinute) break;
      hours.push(
        `${hour.toString().padStart(2, "0")}:${minute
          .toString()
          .padStart(2, "0")}`
      );
    }
  }
  return hours;
};

// Vérifie si une heure est disponible pour un jour donné
export const isHourAvailable = (
  hour: string,
  day: Date,
  daysOfWeek: string[],
  serviceDuration: number,
  appointments: Appointment[],
  cartDates: Date[] // Ajoutez ce paramètre
): boolean => {
  const dayOfWeek = daysOfWeek[day.getDay()];
  const availableHours = getAvailableHours(dayOfWeek, serviceDuration);

  if (!availableHours.includes(hour)) {
    return false;
  }

  const [hourNum, minuteNum] = hour.split(":").map(Number);
  const appointmentExists = appointments.some((appointment) => {
    const appointmentDate = new Date(appointment.date);
    return (
      appointmentDate.getFullYear() === day.getFullYear() &&
      appointmentDate.getMonth() === day.getMonth() &&
      appointmentDate.getDate() === day.getDate() &&
      appointmentDate.getHours() === hourNum &&
      appointmentDate.getMinutes() === minuteNum
    );
  });

  if (appointmentExists) {
    return false;
  }

  const now = new Date();
  const slotTime = new Date(day);
  slotTime.setHours(hourNum, minuteNum, 0, 0); // Réinitialise les secondes et millisecondes

  if (slotTime < now) {
    return false;
  }

  const twoHoursBefore = new Date(now);
  twoHoursBefore.setHours(now.getHours() + 2);

  if (slotTime < twoHoursBefore) {
    return false;
  }

  // Vérifiez si l'heure est déjà dans le panier
  const isInCart = cartDates.some((cartDate) => {
    return (
      cartDate.getFullYear() === day.getFullYear() &&
      cartDate.getMonth() === day.getMonth() &&
      cartDate.getDate() === day.getDate() &&
      cartDate.getHours() === hourNum &&
      cartDate.getMinutes() === minuteNum
    );
  });

  if (isInCart) {
    return false;
  }

  return true;
};

// Gère le clic sur une heure disponible
export const handleHourClick = (
  hour: string,
  day: Date,
  setSelectedDate: Dispatch<React.SetStateAction<Date | null>>,
  setSelectedHour: Dispatch<React.SetStateAction<string | null>>,
  onDateSelect: (date: Date) => void,
  onDateTimeSelect: (date: Date) => void,
  dispatch: Dispatch<any>, // Utilisez le type approprié pour Redux si possible
  cartId: string
) => {
  const newSelectedDateTime = new Date(day);
  const [hourNum, minutes] = hour.split(":").map(Number);
  newSelectedDateTime.setHours(hourNum, minutes, 0, 0);

  setSelectedDate(newSelectedDateTime);
  setSelectedHour(hour);
  onDateSelect(newSelectedDateTime);
  onDateTimeSelect(newSelectedDateTime);
  cartApi.updateCartDate(cartId, newSelectedDateTime);
  dispatch(CloseAnyModalAction());
};
// 

// Gère le changement de semaine
export const handleWeekChange = (
  newWeek: Date[],
  setCurrentWeek: Dispatch<React.SetStateAction<Date[]>>,
  setSelectedDate: Dispatch<React.SetStateAction<Date | null>>
) => {
  setCurrentWeek(newWeek);
  setSelectedDate(null);
};

