import { useState } from "react";
import { Button, Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../common/router";
import toast from "../../../common/services/toast";
import { registerAction } from "../../state/auth.slice";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

type FormType = {
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  password: string;
  passwordConfirm: string;
};

const RegisterForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm(); // Use Form.useForm to access form methods

  const [passwordCriteria, setPasswordCriteria] = useState({
    minLength: false,
    hasNumber: false,
    hasSpecialChar: false,
  });
  
  const [passwordValue, setPasswordValue] = useState(""); // Track the password value

  const validatePassword = (password: string) => {
    setPasswordValue(password); // Update the password value state
    setPasswordCriteria({
      minLength: password.length >= 8,
      hasNumber: /[0-9]/.test(password),
      hasSpecialChar: /[!@#$%^&*]/.test(password),
    });
  };

  const register = async (formData: FormType) => {
    try {
      await dispatch(registerAction(formData)); // Attendre que l'action soit terminée
      toast.success("Un email de confirmation vous a été envoyé");
      navigate(ROUTES.AUTH.LOGIN);
    } catch (error) {
      toast.error("Une erreur est survenue lors de l'inscription");
    }
  };

  return (
    <>
      <Form<FormType> layout="vertical" onFinish={register} form={form} className="">
        <Form.Item
          label={t("UI.REGISTER_PAGE.FORM.fields.firstName.label")}
          name="firstName"
          rules={[{ required: true, message: t("UI.REGISTER_PAGE.FORM.fields.firstName.required") }]}
        >
          <Input
            type="text"
            placeholder={t("UI.REGISTER_PAGE.FORM.fields.firstName.placeholder")}
          />
        </Form.Item>
        <Form.Item
          label={t("UI.REGISTER_PAGE.FORM.fields.lastName.label")}
          name="lastName"
          rules={[{ required: true, message: t("UI.REGISTER_PAGE.FORM.fields.lastName.required") }]}
        >
          <Input
            type="text"
            placeholder={t("UI.REGISTER_PAGE.FORM.fields.lastName.placeholder")}
          />
        </Form.Item>
        <Form.Item
          label={t("UI.REGISTER_PAGE.FORM.fields.email.label")}
          name="email"
          rules={[
            { required: true, message: t("UI.REGISTER_PAGE.FORM.fields.email.required") },
            { type: "email", message: t("UI.REGISTER_PAGE.FORM.fields.email.invalid") }
          ]}
        >
          <Input
            type="email"
            placeholder={t("UI.REGISTER_PAGE.FORM.fields.email.placeholder")}
          />
        </Form.Item>
        <Form.Item
          label={t("UI.REGISTER_PAGE.FORM.fields.mobile.label")}
          name="mobile"
          rules={[{ required: true, message: t("UI.REGISTER_PAGE.FORM.fields.mobile.required") }]}
        >
          <Input
            type="tel"
            placeholder={t("UI.REGISTER_PAGE.FORM.fields.mobile.placeholder")}
          />
        </Form.Item>

        <Form.Item
          name="password"
          label={t("UI.REGISTER_PAGE.FORM.fields.password.label")}
          rules={[
            {
              required: true,
              message: t("UI.REGISTER_PAGE.FORM.fields.password.required"),
            },
            {
              min: 8,
            },
            {
              pattern: /^(?=.*[0-9])/,
            },
            {
              pattern: /^(?=.*[!@#$%^&*])/,
            },
          ]}
        >
          <Input.Password
            placeholder={t("UI.REGISTER_PAGE.FORM.fields.password.placeholder")}
            onChange={(e) => validatePassword(e.target.value)}
          />
        </Form.Item>

        {passwordValue && (
          <div className="mt-2">
            <h3>{t("UI.REGISTER_PAGE.FORM.fields.password.checklist.title")}</h3>
            <ul>
              <li>
                {passwordCriteria.minLength ? (
                  <CheckCircleOutlined
                    style={{ color: 'green', marginRight: '8px' }}
                  />
                ) : (
                  <CloseCircleOutlined
                    style={{ color: 'red', marginRight: '8px' }}
                  />
                )}
                {t("UI.REGISTER_PAGE.FORM.fields.password.checklist.minLength")}
              </li>
              <li>
                {passwordCriteria.hasNumber ? (
                  <CheckCircleOutlined
                    style={{ color: 'green', marginRight: '8px' }}
                  />
                ) : (
                  <CloseCircleOutlined
                    style={{ color: 'red', marginRight: '8px' }}
                  />
                )}
                {t("UI.REGISTER_PAGE.FORM.fields.password.checklist.hasNumber")}
              </li>
              <li>
                {passwordCriteria.hasSpecialChar ? (
                  <CheckCircleOutlined
                    style={{ color: 'green', marginRight: '8px' }}
                  />
                ) : (
                  <CloseCircleOutlined
                    style={{ color: 'red', marginRight: '8px' }}
                  />
                )}
                {t("UI.REGISTER_PAGE.FORM.fields.password.checklist.hasSpecialChar")}
              </li>
            </ul>
          </div>
        )}

        <Form.Item
          name="passwordConfirm"
          label={t("UI.REGISTER_PAGE.FORM.fields.passwordConfirm.label")}
          rules={[
            {
              required: true,
              message: t("UI.REGISTER_PAGE.FORM.fields.passwordConfirm.required"),
            },
            {
              validator: (_, value) =>
                value && value === form.getFieldValue("password")
                  ? Promise.resolve()
                  : Promise.reject(new Error(t("UI.REGISTER_PAGE.FORM.fields.passwordConfirm.mismatch"))),
            },
          ]}
        >
          <Input.Password
            placeholder={t("UI.REGISTER_PAGE.FORM.fields.passwordConfirm.placeholder")}
          />
        </Form.Item>

        <Button htmlType="submit" className="w-full btn">
          {t("UI.REGISTER_PAGE.FORM.submit")}
        </Button>
        
        <div className="mt-8 justify-center text-center">
          <Link
            className="text-[#5B5B5B] hover:text-jb-primary font-medium font-yusei"
            to={ROUTES.AUTH.LOGIN}
          >
            {t("UI.REGISTER_PAGE.loginLink")}
          </Link>
        </div>
      </Form>
    </>
  );
};

export default RegisterForm;
