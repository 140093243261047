import AppointmentSVG from "./icons/sidenavIcons/appointment";
import InvoiceSvg from "./icons/sidenavIcons/invoice";
import ListAppointmentSVG from "./icons/sidenavIcons/list_appointment";
import LogsSVG from "./icons/sidenavIcons/logs";
import ProfileSvg from "./icons/sidenavIcons/profile";
import ServiceSvg from "./icons/sidenavIcons/service";
import { UserRolesEnum } from "../../../User/types/UserTypes";
import { ROUTES } from "../../router";

export type SidebarLink = {
  id: string;
  label: string;
  route?: string;
  icon: JSX.Element;
  badge?: number;
  onClick?: () => void;
};

export type SidebarSection = {
  title: string;
  links: SidebarLink[];
};

export const getSidebarLinks = (role: UserRolesEnum, cartCount = 0): SidebarSection[] => {

  switch (role) {
    case UserRolesEnum.client:
      return [
        {
          title: "Prise de rendez-vous",
          links: [
            { id: "dashboard", label: "Mes rendez-vous", route: ROUTES.CLIENT.DASHBOARD, icon: <ListAppointmentSVG /> },
            { id: "services", label: "Prestations", route: ROUTES.CLIENT.SERVICES, icon: <ServiceSvg /> },
            { id: "cart", label: "Panier", route: ROUTES.CLIENT.CART, icon: <AppointmentSVG />, badge: cartCount },
            { id: "invoice", label: "Factures", route: ROUTES.CLIENT.INVOICE, icon: <InvoiceSvg /> },
          ],
        },
        {
          title: "Paramètres",
          links: [
            { id: "profile", label: "Profil", route: ROUTES.CLIENT.PROFILE, icon: <ProfileSvg /> },
          ],
        },
      ];

    case UserRolesEnum.esthetician:
      return [
        {
          title: "Prise de rendez-vous",
          links: [
            { id: "dashboard", label: "Dashboard", route: ROUTES.ESTH.DASHBOARD, icon: <ListAppointmentSVG /> },
            { id: "logs", label: "Journals de logs", route: ROUTES.ESTH.LOGS, icon: <LogsSVG /> },
          ],
        },
      ];

    case UserRolesEnum.admin:
      return [
        {
          title: "Gestion",
          links: [
            { id: "accounts", label: "Voir les comptes", route: ROUTES.ADMIN.ACCOUNTS, icon: <ProfileSvg /> },
            { id: "appointments", label: "Voir les rendez-vous", route: ROUTES.ADMIN.DASHBOARD, icon: <AppointmentSVG /> },
            { id: "services", label: "Voir les services", route: ROUTES.ADMIN.SERVICES, icon: <ServiceSvg /> },
          ],
        },
      ];

    default:
      return [];
  }
};
