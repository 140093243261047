import React from "react";

const ListAppointmentSVG = ({ color = "black" }) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.33325 2.08337V5.20837"
      stroke={color}
      strokeWidth="1"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.6667 2.08337V5.20837"
      stroke={color}
      strokeWidth="1"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.64575 9.46875H21.3541"
      stroke={color}
      strokeWidth="1"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.875 8.85421V17.7084C21.875 20.8334 20.3125 22.9167 16.6667 22.9167H8.33333C4.6875 22.9167 3.125 20.8334 3.125 17.7084V8.85421C3.125 5.72921 4.6875 3.64587 8.33333 3.64587H16.6667C20.3125 3.64587 21.875 5.72921 21.875 8.85421Z"
      stroke={color}
      strokeWidth="1"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.4953 14.2708H12.5047"
      stroke={color}
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.63987 14.2708H8.64922"
      stroke={color}
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.63987 17.3958H8.64922"
      stroke={color}
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ListAppointmentSVG;
