import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { MODAL_SERVICE, openDrawerPayload, openModalPayload } from "../state/types";
import { DrawerProps, ModalProps } from "antd";

// to implement

type ModalServiceStateType = {
  isModalOpen: boolean,
  modalComponent: React.ReactNode | null,
  modalOptions?: Pick<ModalProps, 'title' | 'afterClose' | 'width'>,
  isDrawerOpen: boolean,
  drawerComponent: React.ReactNode | null
  drawerOptions?: Pick<DrawerProps, 'title' | 'width' | 'size' | 'placement'>,
};

const modalInitialState: ModalServiceStateType = {
  isModalOpen: false,
  modalComponent: undefined,
  modalOptions: undefined,
  isDrawerOpen: false,
  drawerComponent: undefined
}

export const modalSlice = createSlice({
  name: MODAL_SERVICE,
  initialState: modalInitialState,
  reducers: {
    openModalAction: (state: ModalServiceStateType, { payload: { component, options } }: PayloadAction<openModalPayload>) => {
      state.isModalOpen = true;
      state.modalComponent = component
      state.modalOptions = options
    },
    closeModalAction: (state: ModalServiceStateType) => {
      state.isModalOpen = false;
      state.modalComponent = null
      state.modalOptions = undefined
    },

    openDrawerAction: (state: ModalServiceStateType, { payload: { component, options } }: PayloadAction<openDrawerPayload>) => {
      state.isDrawerOpen = true;
      state.drawerComponent = component
      state.drawerOptions = options; // Modifier ici pour utiliser drawerOptions au lieu de modalOptions
    },
    closeDrawerAction: (state: ModalServiceStateType) => {
      state.isDrawerOpen = false;
      state.drawerComponent = null
      state.modalOptions = undefined
    },

    CloseAnyModalAction: (state: ModalServiceStateType) => {
      state.isDrawerOpen = false;
      state.drawerComponent = null;
      state.modalOptions = undefined;

      state.isModalOpen = false;
      state.modalComponent = null;
      state.modalOptions = undefined;
    },

  }
});


export const {
  openModalAction,
  closeModalAction,
  openDrawerAction,
  closeDrawerAction,

  CloseAnyModalAction
} = modalSlice.actions;

export default modalSlice.reducer;

export const selectModalService = (state: RootState) => state.modalService
export const selectModal = createSelector(selectModalService, (modalSvc) => ({
  isModalOpen: modalSvc.isModalOpen,
  modalOptions: modalSvc.modalOptions,
  modalComponent: modalSvc.modalComponent
}))
export const selectModalOpened = createSelector(selectModalService, (modalSvc) => modalSvc.isModalOpen)
export const selectModalComponent = createSelector(selectModalService, (modalSvc) => modalSvc.modalComponent)
export const selectModalOptions = createSelector(selectModalService, (modalSvc) => modalSvc.modalOptions)

export const selectDrawer = createSelector(selectModalService, (modalSvc) => ({
  isDrawerOpen: modalSvc.isDrawerOpen,
  drawerOptions: modalSvc.drawerOptions,
  drawerComponent: modalSvc.drawerComponent
}))
export const selectDrawerOpened = createSelector(selectModalService, (modalSvc) => modalSvc.isDrawerOpen)
export const selectDrawerComponent = createSelector(selectModalService, (modalSvc) => modalSvc.drawerComponent)
export const selectDrawerOptions = createSelector(selectModalService, (modalSvc) => modalSvc.drawerOptions)