
/* ======== CONSTANTES ======== */

import { DrawerProps, ModalProps } from "antd";
import { ReactNode } from "react";

/* ======== TYPES ======== */

export const MODAL_SERVICE = "modalService";
// eslint-disable-next-line
export type MODAL_SERVICE = typeof MODAL_SERVICE; // Typescript line

/* ======== PAYLOADS ======== */

export type openModalPayload = {
  component: ReactNode,
  options?: ModalProps
};

export type openDrawerPayload = {
  component: ReactNode,
  options?: DrawerProps
};

