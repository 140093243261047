import { CalendarOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useState } from "react";
import { selectUser } from "../Auth/state/auth.slice";
import PageCanvas from "../common/components/Panels/PageCanvas";
import { useAppSelector } from "../common/hooks";
import AppointmentDashboardEsth from "../Esth/AppointmentDashboardEsth/AppointmentDashboardEsth";
import { UserRolesEnum } from "../User/types/UserTypes";
import AppointmentDashboardClient from "../Client/AppointmentDashboardClient";
import { useMediaQuery } from "react-responsive";
import AdminListAppointmentPage from "../Admin/AdminListAppointment/AdminListAppointmentPage";

const AppointmentDashboardPage = () => {
  const user = useAppSelector(selectUser);
  const [viewAppointmentsArchived, setViewAppointmentsArchived] =
    useState(false);

  const handleViewAppointmentsArchived = () => {
    setViewAppointmentsArchived(!viewAppointmentsArchived);
  };

  const isMobile = useMediaQuery({ query: "(max-width: 1280px)" });


  return (
    <>
      <PageCanvas title="Dashboard">
        <PageCanvas.Actions>
          {user!.role === UserRolesEnum.client && (
            // n'afficher que lorsque c'est différent de isMobile
            !isMobile && (
              <Button
                shape="circle"
                icon={<CalendarOutlined />}
                onClick={handleViewAppointmentsArchived}
              />
            )
          )}{" "}
        </PageCanvas.Actions>

        <PageCanvas.Content>
          {user!.role === UserRolesEnum.admin && <AdminListAppointmentPage />}
          {user!.role === UserRolesEnum.esthetician && (<AppointmentDashboardEsth />)}
          {user!.role === UserRolesEnum.client && (<AppointmentDashboardClient viewAppointmentsArchived={viewAppointmentsArchived}/>)}
        </PageCanvas.Content>
      </PageCanvas>
    </>
  );
};

export default AppointmentDashboardPage;
