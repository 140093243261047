import React, { useEffect } from 'react';

const AxeptioScriptLoader: React.FC = () => {
  useEffect(() => {
    window.axeptioSettings = {
      clientId: "66c887ea8d5ad420eee1917a",
      cookiesVersion: "jbconnect91-fr-EU",
      googleConsentMode: {
        default: {
          analytics_storage: "denied",
          ad_storage: "denied",
          ad_user_data: "denied",
          ad_personalization: "denied",
          wait_for_update: 500
        }
      }
    };
  
    // Charger le script Axeptio de manière sécurisée
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://static.axept.io/sdk.js"; // Utilisation explicite de HTTPS
    document.body.appendChild(script);
    window.axeptioSettings.debug = true;
    script.onerror = () => {
      console.error("Error loading Axeptio script");
    };
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return null;
};

export default AxeptioScriptLoader;