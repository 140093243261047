import { IUser, UserRolesEnum } from "./UserTypes";

export class User implements IUser {
  email!: string;
  password?: string | undefined;
  role!: UserRolesEnum;
  firstName?: string | undefined;
  lastName?: string | undefined;
  mobile?: string | undefined;
  picture?: string | undefined;
  resetPasswordToken?: string | undefined;
  resetPasswordExpires?: Date | undefined;

  id?: string | undefined;
  _id?: string | undefined;
  updatedAt?: Date | undefined;
  createdAt?: Date | undefined;

  public constructor(init?: Partial<User>) {
    Object.assign(this, {
      ...init,
    });
  }

  getFullName?() {
    if (this.firstName && this.lastName)
      return `${this.firstName} ${this.lastName}`;
    else if (this.firstName) return `${this.firstName}`;
    else if (this.lastName) return `${this.lastName}`;
  }
}
