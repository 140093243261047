import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../hooks";
import { closeModalAction } from "../../state/modal.slice";
import { ReactNode } from "react";

type PropsType = {
  confirmIsDanger?: boolean,
  body?: ReactNode,
  yesLabel?: string,
  noLabel?: string,
  formCb?: (res?: boolean) => void
}

const ModalConfirm = (props: PropsType) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch();

  const defaultOptions: PropsType = {
    noLabel: t('COMMON.MESSAGES.no'),
    yesLabel: t('COMMON.MESSAGES.yes'),
    body: '',
    confirmIsDanger: false
  }
  Object.assign(defaultOptions, props)

  const { confirmIsDanger, body, yesLabel, noLabel, formCb } = defaultOptions;

  const closeModal = (result?: boolean) => {
    dispatch(closeModalAction())
    if (formCb)
      formCb(result)
  }

  return <>
    <div>{body}</div>
    <div className="flex justify-end space-x-4">
      <Button htmlType="button" onClick={_ => closeModal(false)} type="link">{noLabel}</Button>
      <Button htmlType="button" onClick={_ => closeModal(true)} type="primary" danger={confirmIsDanger} >{yesLabel}</Button>
    </div>
  </>


}

export default ModalConfirm