import { Navigate, Outlet } from "react-router-dom";
import { selectAuthService } from "../../Auth/state/auth.slice";
import { useAppSelector } from "../../common/hooks";
import { ROUTES } from "../../common/router";
import { UserRolesEnum } from "../types/UserTypes";

interface RoleGuardProps {
  expectedRole: UserRolesEnum;
}

const RoleGuard = ({ expectedRole }: RoleGuardProps) => {
  const { auth, refreshing } = useAppSelector(selectAuthService);

  if (refreshing) return <div>Chargement...</div>;

  if (auth && auth.user.role === expectedRole) {
    return <Outlet />;
  }

  if (auth) {
    return <Navigate to="/error" />;
  }

  return <Navigate to={ROUTES.AUTH.LOGIN} />;
};

export default RoleGuard;
