import React from 'react';
import { Collapse } from 'antd';
import { Appointment } from '../types/modelAppointment';
import AvailableHours from './AvailableHours';
import WeekNavigation from './WeekNavigation';

interface CalendarMobileProps {
  currentWeek: Date[];
  daysOfWeek: string[];
  serviceDuration: number;
  appointments: Appointment[];
  selectedHour: string | null;
  selectedDate: Date | null;
  handleHourClick: (hour: string, day: Date) => void;
  handleWeekChange: (
    newWeek: Date[],
    setCurrentWeek: React.Dispatch<React.SetStateAction<Date[]>>,
    setSelectedDate: React.Dispatch<React.SetStateAction<Date | null>>
  ) => void;
  goToPreviousWeek: (currentWeek: Date[]) => Date[];
  goToNextWeek: (currentWeek: Date[]) => Date[];
  setCurrentWeek: React.Dispatch<React.SetStateAction<Date[]>>;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date | null>>;
}


const CalendarMobile: React.FC<CalendarMobileProps> = ({
  currentWeek,
  daysOfWeek,
  serviceDuration,
  appointments,
  selectedHour,
  selectedDate,
  handleHourClick,
  handleWeekChange,
  goToPreviousWeek,
  goToNextWeek,
  setCurrentWeek,
  setSelectedDate,
}) => (
  <Collapse>
    <WeekNavigation
      goToPreviousWeek={() =>
        handleWeekChange(goToPreviousWeek(currentWeek), setCurrentWeek, setSelectedDate)
      }
      goToNextWeek={() =>
        handleWeekChange(goToNextWeek(currentWeek), setCurrentWeek, setSelectedDate)
      }
      currentWeek={currentWeek}
    />
    {currentWeek.map((date) => (
      <Collapse.Panel
        header={date.toLocaleDateString('fr-FR', { day: 'numeric', month: 'long' })}
        key={date.toISOString()}
      >
        <AvailableHours
          date={date}
          daysOfWeek={daysOfWeek}
          serviceDuration={serviceDuration}
          appointments={appointments}
          selectedHour={selectedHour}
          selectedDate={selectedDate}
          handleHourClick={(hour: string, day: Date) => handleHourClick(hour, day)}
          dayIndex={currentWeek.indexOf(date)}
          gridColumns={1}
        />
      </Collapse.Panel>
    ))}
  </Collapse>
);

export default CalendarMobile;
