import { IInvoice } from "./invoiceTypes";

export class Invoice implements IInvoice {
  userId!: string;
  fileName!: string;
  fileData!: Buffer;


  id?: string;
  _id?: string;
  updatedAt?: Date;
  createdAt?: Date;

  constructor(service: IInvoice) {
    Object.assign(this, service);
  }

}

export interface IInvoiceRef {
  serviceId: IInvoice["_id"];
}
