import PageCanvas from "../../common/components/Panels/PageCanvas";
import AdminListUser from "./AdminListUser";

const AdminListUserPage = () => {
  return (
    <>
      <PageCanvas title="Liste des comptes">
        <PageCanvas.Content>
          <AdminListUser />
        </PageCanvas.Content>
      </PageCanvas>
    </>
  );
};

export default AdminListUserPage;
