import PageCanvas from "../../../common/components/Panels/PageCanvas";
import UserProfileFormInfo from "./UserProfileFormInfo";

const UserProfilePage = () => {
  return (
    <>
      <PageCanvas title="Votre profil">
        <PageCanvas.Content>
          <UserProfileFormInfo />
        </PageCanvas.Content>
      </PageCanvas>
    </>
  );
};

export default UserProfilePage;
