import { IService } from "../../Services/services/types/serviceTypes";
import { IUser } from "../../User/types/UserTypes";

export interface IAppointment {
  date: Date;
  status: AppointmentStatusEnum;
  
  serviceId: IService;
  userId: IUser;

  id?: string | undefined;
  _id?: string | undefined;
  updatedAt?: Date | undefined;
  createdAt?: Date | undefined;
}

export enum AppointmentStatusEnum {
  cancelled = -1,
  confirmed = 2,
  finished = 3,
}

export const AppointmentStatusEnumLabels: {
  [key in AppointmentStatusEnum]: string;
} = {
  [AppointmentStatusEnum.cancelled]: "Annulé",
  [AppointmentStatusEnum.confirmed]: "Confirmé",
  [AppointmentStatusEnum.finished]: "Terminé",
};
