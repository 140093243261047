import { Empty, Result } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { AppointmentStatusEnum } from "../../Appointment/types/appointmentTypes";
import { Appointment } from "../../Appointment/types/modelAppointment";
import Fieldset from "../../common/components/Panels/Fieldset";
import { ApiListResult } from "../../common/services/types";
import esthApi from "../services/esth.api";

const AppointmentDashboardEsth: React.FC = () => {
  const [appointments, setAppointments] =
    useState<ApiListResult<Appointment>>();
  const fetchAppointments = async () => {
    esthApi
      .findAppointmentsEsth({}, 1, 10)
      .then((res) => setAppointments(res))
      .catch(console.log);
  };

  useEffect(() => {
    fetchAppointments();
  }, []);

  interface AppointmentGroups {
    [day: string]: Appointment[];
  }
  const appointmentsByDay = appointments?.rows
    .filter(
      (appointment) =>
        appointment.status !== AppointmentStatusEnum.cancelled &&
        dayjs(appointment.date).isAfter(dayjs()) // Filtrer les rendez-vous à venir
    )
    .reduce<AppointmentGroups>((groups, appointment) => {
      const day = dayjs(appointment.date).format("dddd");
      if (!groups[day]) {
        groups[day] = [];
      }
      groups[day].push(appointment);
      return groups;
    }, {});

  // Calculate the total price for each day
  const totalPricesByDay = Object.keys(appointmentsByDay || {}).reduce(
    (acc, day) => {
      const appointmentsForDay = appointmentsByDay![day];
      const totalPrice = appointmentsForDay.reduce(
        (sum, appointment) => sum + Number(appointment.serviceId.price),
        0
      );
      acc[day] = totalPrice;
      return acc;
    },
    {} as { [day: string]: number }
  );

  return (
    <Fieldset title="Vue d'ensemble des rendez-vous : Esthéticienne ">
      <div className="flex flex-col space-y-2 overflow-y-scroll max-h-[700px]">
        {appointmentsByDay && Object.keys(appointmentsByDay).length > 0 ? (
          Object.entries(appointmentsByDay).map(([day, appointments], index) => (
            <div key={`${day}-${index}`}>
              <div className="border-b border-gray-300">
                <div className="flex justify-between">
                  <h2 className="capitalize font-h2">
                    <span className="font-yusei">{day}</span> |{" "}
                    {dayjs(appointments[0].date).format("DD MMM")}
                  </h2>
                  <p className="flex items-center mr-4 font-yusei font-light text-gray-500">
                    {appointments.length} rendez-vous | {totalPricesByDay[day]} €
                  </p>
                </div>
              </div>
              {appointments.map((appointment, index) => (
                <div
                  key={index}
                  className="my-4 w-full text-white aspect-w-4 aspect-h-1 rounded-lg p-8 grid grid-cols-2 relative"
                  style={{
                    backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 10%, rgba(0, 0, 0, 0.2) 20%, rgba(0, 0, 0, 0.3) 60%, #c79d79 100%, #c79d79 80%), url(${appointment.serviceId.backgroundImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    filter: "brightness(90%)",
                  }}
                >
                  <div className="flex flex-col space-y-4 items-start justify-center">
                    <h3 className="text-3xl font-yusei font-medium mb-1">
                      {appointment.serviceId.name}
                    </h3>
                    <p className="text-xl font-light">
                      <span className="font-yusei">Rendez-vous à :</span>{" "}
                      <strong>{dayjs(appointment.date).format("HH:mm")}</strong>
                    </p>
                    <p className="text-xl font-light mt-8">
                      <span className="font-yusei">Avec :</span>{" "}
                      <strong>
                        {appointment.userId.firstName}{" "}
                        {appointment.userId.lastName}
                      </strong>
                    </p>
                    <p className="text-md font-light">
                      <span className="font-yusei">Téléphone :</span>{" "}
                      <strong>{appointment.userId.mobile}</strong>
                    </p>
                  </div>
                  <div className="flex items-end justify-end">
                    <strong className="text-2xl font-bold ">
                      {appointment.serviceId.price} €
                    </strong>
                  </div>
                </div>
              ))}
            </div>
          ))
        ) : (
          <Result
            className="mt-20"
            icon={<Empty description={""} />}
            title="Aucun rendez-vous à afficher."
          />
        )}
      </div>
    </Fieldset>
  );
};

export default AppointmentDashboardEsth;