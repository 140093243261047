import { defaultPagination } from "../../common/app-constants";
import ApiService from "../../common/services/apiService";
import { ApiListResult } from "../../common/services/types";
import { IAppointment } from "../types/appointmentTypes";
import { Appointment } from "../types/modelAppointment";

const APPOINTMENT_PATH_API = "appointments";

class AppointmentApi extends ApiService<IAppointment> {
  constructor() {
    super(APPOINTMENT_PATH_API);
  }

  findAppointments = (
    query = {},
    page = defaultPagination.page,
    pageSize = defaultPagination.pageSize
  ): Promise<{ count: number; rows: Appointment[] }> => {
    return this.find({ query, page, pageSize }).then((res) => {
      if (!res || !res.rows) {
        throw new Error("Invalid response structure");
      }
      return {
        count: res.count,
        rows: res.rows.map((r) => new Appointment(r)),
      };
    });
  };

  createAppointment = (appointment: IAppointment): Promise<Appointment> => {
    return this.create(appointment).then((r) => new Appointment(r));
  };

  getAppointment = (appointmentId: string): Promise<Appointment> => {
    return this.get(appointmentId).then((r) => new Appointment(r));
  }

  findAppointmentsForCreateAppointment = (
    query = {},
    page = defaultPagination.page,
    pageSize = defaultPagination.pageSize
  ): Promise<{ count: number; rows: Appointment[] }> => {
    return this.custom<ApiListResult<Appointment>>(
      "get",
      `getAllAppointmentsForCreateAppointment`,
      {
        query,
        page,
        pageSize,
      }
    ).then((res) => {
      return {
        count: res.count,
        rows: res.rows.map((r) => new Appointment(r)),
      };
    });
  };

  cancelAppointment = (
    appointmentId: string,
    status: string
  ): Promise<Appointment> => {
    return this.custom<Appointment>(
      "put",
      `/cancel/${appointmentId}`,
      {},
      { status }
    ).then((r) => new Appointment(r));
  };
}

const appointmentApi = new AppointmentApi();

export default appointmentApi;
