import PageCanvas from "../../common/components/Panels/PageCanvas";
import Logs from "./Logs";

const LogPage = () => {
  return (
    <>
      <PageCanvas title={"Journal de logs"}>
        <PageCanvas.Content>
          <Logs/>
        </PageCanvas.Content>
      </PageCanvas>
    </>
  );
};

export default LogPage;
