import { IAppointment } from "../../../Appointment/types/appointmentTypes";
import { IService } from "../../../Services/services/types/serviceTypes";
import { IUser } from "../../../User/types/UserTypes";

export interface ILog {
  userId: IUser;
  typeAction: TimelineStatusEnum;
  descriptionAction: string;
  timestamp: Date;
  serviceId: IService;
  appointmentId: IAppointment;

  id?: string;
  _id?: string;
  updatedAt?: Date;
  createdAt?: Date;
}

export enum TimelineStatusEnum {
  create = "create",
  read = "read",
  update = "update",
  delete = "delete",
}

export const TimelineStatusEnumLabels: {
  [key in TimelineStatusEnum]: string;
} = {
  [TimelineStatusEnum.create]: "Création",
  [TimelineStatusEnum.read]: "Lecture",
  [TimelineStatusEnum.update]: "Mise à jour",
  [TimelineStatusEnum.delete]: "Suppression",
};
