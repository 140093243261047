const url = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME_CLOUDINARY}/image/upload`;

const uploadImage = async (image: any) => {
  const formData = new FormData();
  formData.append("file", image);
  formData.append("upload_preset", "wr8x6vzw");  // Remplacer par le bon nom si nécessaire
  formData.append("public_id",  image.name.substring(0, image.name.lastIndexOf(".")) + Date.now());  // Nom du fichier sur Cloudinary

  try {
    const dataResponse = await fetch(url, {
      method: "POST",
      body: formData,
    });

    if (!dataResponse.ok) {
      throw new Error("Upload failed");
    }

    return dataResponse.json();
  } catch (error) {
    console.error("Erreur lors de l'upload de l'image :", error);
    throw error;
  }
};

export default uploadImage;
