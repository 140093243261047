import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";
import AdminListUserPage from "../Admin/AdminListAccount/AdminListUserPage";
import AdminListAppointmentPage from "../Admin/AdminListAppointment/AdminListAppointmentPage";
import AdminListServicesPage from "../Admin/AdminListService/AdminListServicesPage";
import AppointmentDashboardPage from "../Appointment/AppointmentDashboardPage";
import AuthRoutes from "../Auth/routes";
import CartPage from "../Cart/CartPage";
import SuccessCart from "../Cart/SuccessCart";
import LogPage from "../Esth/Logs/LogsPage";
import InvoicePage from "../Invoice/InvoicePage";
import ChartOfConfidentialityPage from "../PartLaw/ChartOfConfidentiality/ChartOfConfidentialityPage";
import TermsAndConditionsPage from "../PartLaw/TermsAndConditions/TermsAndConditionsPage";
import TermOfUsePage from "../PartLaw/TermsOfUse/TermOfUsePage";
import ServicesPage from "../Services/ServicesPage";
import UserProfilePage from "../User/components/UserProfile/UserProfilePage";
import RoleGuard from "../User/guards/RoleGuard";
import { UserRolesEnum } from "../User/types/UserTypes";
import NotFound from "./components/Pages/NotFound";
import { default as EventLayout, default as Layout } from "./layouts/EventLayout/Layout";
import ModalService from "./services/modal.service";

export const ROUTES = {
  // Auth routes
  AUTH: {
    LOGIN: "/connect/login",
    REGISTER: "/connect/register",
    RESET_PASSWORD: "/connect/new-password",
  },

  // Client routes
  CLIENT: {
    EVENT: "/app",
    DASHBOARD: "/app/client/appointments/dashboard",
    SERVICES: "/app/client/appointments/services",
    PROFILE: "/app/client/appointments/profile",
    CART: "/app/client/appointments/new",
    INVOICE: "/app/client/appointments/invoices",
  },

  // Esthéticienne routes
  ESTH: {
    DASHBOARD: "/app/esth/appointments/dashboard",
    LOGS: "/app/esth/logs",
  },

  // Admin routes
  ADMIN: {
    DASHBOARD: "/app/admin/appointments/list-appointments",
    SERVICES: "/app/admin/appointments/list-services",
    ACCOUNTS: "/app/admin/appointments/list-accounts",
    PROFILE: "/app/admin/appointments/profile",
  },

  // Legal routes
  LEGAL: {
    TERMS_OF_USE: "/app/terms-of-use",
    CHART_OF_CONFIDENTIALITY: "/app/chart-of-confidentiality",
    TERMS_AND_CONDITIONS: "/app/terms-and-conditions",
  },
};


export default createBrowserRouter([
  {
    errorElement: <NotFound />,
    path: "",
    element: (
      <>
        <Outlet />
        <ModalService />
      </>
    ),
    children: [
      {
        path: "connect",
        children: [...AuthRoutes],
      },
      {
        path: "",
        element: <Navigate to={ROUTES.AUTH.LOGIN} />,
      },
      {
        path: "app",
        children: [
          {
            path: "client",
            element: <RoleGuard expectedRole={UserRolesEnum.client} />,
            children: [
              {
                path: "appointments",
                element: <Layout />,
                children: [
                  {
                    path: "dashboard",
                    element: <AppointmentDashboardPage />,
                  },

                  {
                    path: "services",
                    element: <ServicesPage />,
                  },
                  {
                    path: "new",
                    element: <CartPage />,
                  },
                  {
                    path: "success",
                    element: <SuccessCart />,
                  },
                  {
                    path: "profile",
                    element: <UserProfilePage />,
                  },
                  {
                    path: "invoices",
                    element: <InvoicePage />
                  },
                ],
              },
            ]
          },
          {
            path: "admin",
            element: <RoleGuard expectedRole={UserRolesEnum.admin} />,
            children: [
              {
                path: "appointments",
                element: <EventLayout />,

                children: [
                  {
                    path: "list-appointments",
                    element: <AdminListAppointmentPage />,
                  },
                  {
                    path: "list-services",
                    element: <AdminListServicesPage />,
                  },
                  {
                    path: "list-accounts",
                    element: <AdminListUserPage />,
                  },
                  {
                    path: "profile",
                    element: <UserProfilePage />,
                  },
                ],
              },
            ],
          },
          {
            path: "esth",
            element: <RoleGuard expectedRole={UserRolesEnum.esthetician} />,
            children: [
              {
                path: "logs",
                element: <EventLayout />,
                children: [
                  {
                    path: "",
                    element: <LogPage />,
                  },
                ],
              },
              {
                path: "appointments",
                element: <EventLayout />,

                children: [
                  {
                    path: "dashboard",
                    element: <AppointmentDashboardPage />,
                  },
                ]
              }
            ]
          },
          {
            path: "terms-of-use",
            children: [
              {
                path: "",
                element: <TermOfUsePage />
              }
            ]
          },
          {
            path: "chart-of-confidentiality",
            children: [
              {
                path: "",
                element: <ChartOfConfidentialityPage />
              }
            ]
          },
          {
            path: "terms-and-conditions",
            children: [
              {
                path: "",
                element: <TermsAndConditionsPage />
              }
            ]
          }
        ]
      }
    ],
  },
]);
