// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */
.select-container {
  transition: all 0.5s ease-in-out;
  transform: translateY(-10px);
  opacity: 0;
  width: -moz-fit-content;
  width: fit-content;
}

.select-container.active {
  transform: translateY(0);
  opacity: 1;
}

@keyframes slide-up {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.price-animation {
  animation: slide-up 0.5s ease-out forwards;
}`, "",{"version":3,"sources":["webpack://./src/Services/CardService.css"],"names":[],"mappings":"AAAA,eAAe;AACf;EACE,gCAAgC;EAChC,4BAA4B;EAC5B,UAAU;EACV,uBAAkB;EAAlB,kBAAkB;AACpB;;AAEA;EACE,wBAAwB;EACxB,UAAU;AACZ;;AAEA;EACE;IACE,2BAA2B;IAC3B,UAAU;EACZ;EACA;IACE,wBAAwB;IACxB,UAAU;EACZ;AACF;;AAEA;EACE,0CAA0C;AAC5C","sourcesContent":["/* styles.css */\n.select-container {\n  transition: all 0.5s ease-in-out;\n  transform: translateY(-10px);\n  opacity: 0;\n  width: fit-content;\n}\n\n.select-container.active {\n  transform: translateY(0);\n  opacity: 1;\n}\n\n@keyframes slide-up {\n  0% {\n    transform: translateY(100%);\n    opacity: 0;\n  }\n  100% {\n    transform: translateY(0);\n    opacity: 1;\n  }\n}\n\n.price-animation {\n  animation: slide-up 0.5s ease-out forwards;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
