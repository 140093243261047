import { ReactNode } from 'react';
import { connect } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { RootState } from './';
import { persistor } from './configureStore';
import { refreshTokenAction, selectIsLoggedIn } from '../../Auth/state/auth.slice';

const mapStateToProps = (state: RootState) => ({
  isLoggedIn: selectIsLoggedIn(state),
});

const mapDispatchToProps = {
  // isRegistrating: isRegistratingAction,
  refreshToken: refreshTokenAction,
  // getMe: getMeActions.request,
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps & {
    children: ReactNode;
    loading: ReactNode;
  };

const StorePersistGate = ({ children, loading, refreshToken, isLoggedIn, /*getMe*/ }: Props) => (
  <PersistGate
    loading={loading}
    onBeforeLift={() => {
      // avoid blocking state
      // isRegistrating(false);

      if (isLoggedIn) {
        refreshToken();
        // getMe();
      }
    }}
    persistor={persistor}
  >
    {children}
  </PersistGate>
);

export default connect(mapStateToProps, mapDispatchToProps)(StorePersistGate);
