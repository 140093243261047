import { IService } from "../../Services/services/types/serviceTypes";
import { IUser } from "../../User/types/UserTypes";
import { AppointmentStatusEnum, IAppointment } from "./appointmentTypes";

export class Appointment implements IAppointment {
  date!: Date;
  status!: AppointmentStatusEnum;
  serviceId!: IService;
  userId!: IUser;

  id?: string;
  _id?: string;
  updatedAt?: Date;
  createdAt?: Date;

  constructor(appointment: IAppointment) {
    Object.assign(this, {
      ...appointment,
      date: new Date(appointment.date),
    });
  }
}
