import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AUTH_SERVICE, AuthStateType, LoginPayload, RegisterPayload } from "./types";
import { RootState } from "../../common/store";
import { AppUser } from "../../User/types";
import { User } from "../../User/types/modelUser";

// to implement

type AuthServiceStateType = {
  auth: AuthStateType<AppUser> | null,
  refreshing: boolean
  error: string | null
};

const authInitialState: AuthServiceStateType = {
  auth: null,
  refreshing: false,
  error: null
}

export const authSlice = createSlice({
  name: AUTH_SERVICE,
  initialState: authInitialState,
  reducers: {
    loginAction: (state: AuthServiceStateType, _payload: PayloadAction<LoginPayload>) => {
      state.auth = null;
      state.refreshing = true;
      state.error = null;  // Réinitialiser l'erreur au début de l'action
    },
    loginSuccessAction: (state: AuthServiceStateType, { payload: auth }: PayloadAction<AuthStateType<AppUser>>) => {
      state.auth = auth;
      state.refreshing = false;
      state.error = null;  // Réinitialiser l'erreur en cas de succès
    },
    loginErrorAction: (state: AuthServiceStateType, { payload: error }: PayloadAction<string>) => {
      state.auth = null;
      state.refreshing = false;
      state.error = error; // Le message d'erreur est directement enregistré
    },
    clearErrorAction: (state) => {
      state.error = null;  // Réinitialiser l'erreur explicitement
    },
    
    refreshTokenAction: (state: AuthServiceStateType, _payload: PayloadAction) => {
      // state.auth = null;
      state.refreshing = true;
    },
    refreshTokenSuccessAction: (state: AuthServiceStateType, { payload: auth }: PayloadAction<AuthStateType<AppUser>>) => {
      state.auth = auth;
      state.refreshing = false;
    },
    refreshTokenErrorAction: (state: AuthServiceStateType, _payload: PayloadAction<any>) => {
      state.auth = null
      state.refreshing = false;
    },

    registerAction: (state: AuthServiceStateType, _payload: PayloadAction<RegisterPayload>) => {
      state.auth = null;
      state.refreshing = true;
    },

    registerSuccessAction: (state: AuthServiceStateType, { payload: auth }: PayloadAction<AuthStateType<AppUser>>) => {
      state.auth = auth;
      state.refreshing = false;
    },
    registerErrorAction: (state: AuthServiceStateType, { payload: error }: PayloadAction<any>) => {
      state.auth = null
      state.refreshing = false;
    },

    logoutAction: (state: AuthServiceStateType, _payload: PayloadAction<undefined>) => {
      state.auth = null
      state.refreshing = false;
    },
    updateUserAction: (state: AuthServiceStateType, { payload: user }: PayloadAction<Partial<User>>) => {
      if (state.auth) {
        state.auth.user = { ...state.auth.user, ...user }
      }
    },

  }
});


export const {
  loginAction,
  loginSuccessAction,
  loginErrorAction,
  clearErrorAction,

  refreshTokenAction,
  refreshTokenSuccessAction,
  refreshTokenErrorAction,

  registerAction,
  registerSuccessAction,
  registerErrorAction,

  logoutAction,
  updateUserAction
} = authSlice.actions;

export default authSlice.reducer;

export const selectAuthService = (state: RootState) => state.authService
export const selectAuth = createSelector(selectAuthService, (authSvc) => authSvc.auth)
export const selectAuthError = createSelector(selectAuthService, (authSvc) => authSvc.error);
export const selectIsLoggedIn = createSelector(selectAuthService, (authSvc) => authSvc.auth !== null)
export const selectUser = createSelector(selectAuth, (auth) => auth?.user)
// export const selectUserRole = createSelector(selectUser, (user) => user?.role)
// export const selectUserSuperAdmin = createSelector(selectUser, (user) => user?.isSuperAdmin === true)
export const selectToken = createSelector(selectAuth, (auth) => auth?.token)
