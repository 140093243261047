import { Button } from "antd";
import React, { useEffect, useState } from "react";
import cartApi from "../../Cart/services/cart.api";
import { useCart } from "../../common/contexts/CartContext";
import { ApiListResult } from "../../common/services/types";
import { Appointment } from "../types/modelAppointment";
import { getAvailableHours, isHourAvailable } from "./dateUtils";

type AvailableHoursProps = {
  date: Date;
  daysOfWeek: string[];
  serviceDuration: number;
  appointments: ApiListResult<Appointment>["rows"];
  selectedHour: string | null;
  selectedDate: Date | null;
  handleHourClick: (hour: string, date: Date) => void;
  dayIndex: number;
  gridColumns: number;
};

const AvailableHours: React.FC<AvailableHoursProps> = ({
  date,
  daysOfWeek,
  serviceDuration,
  appointments,
  selectedHour,
  selectedDate,
  handleHourClick,
  dayIndex,
  gridColumns,
}) => {
  const { cart,  } = useCart();
  const [selectedSlots, setSelectedSlots] = useState<Date[]>([]);

  useEffect(() => {
    const fetchSelectedSlots = async () => {
      try {
        const res = await cartApi.fetchCart();
        const selectedSlots = res.rows.map((item) => new Date(item.date));
        setSelectedSlots(selectedSlots);
      } catch (error) {
        console.error("Error fetching cart:", error);
      }
    };

    fetchSelectedSlots();
  }, [cart]);

  const dayOfWeekLabel = daysOfWeek[date.getDay()];

  return (
    <div
      className={`col-span-1 day-${dayIndex}`}
      style={{ gridColumn: dayIndex + 1 }}
    >
      <div className="hours grid grid-cols-1 gap-x-4 gap-y-2 p-2">
        {getAvailableHours(dayOfWeekLabel, serviceDuration).map((hour) => {
          const isSlotAvailable = isHourAvailable(
            hour,
            date,
            daysOfWeek,
            serviceDuration,
            appointments,
            selectedSlots
          );

          return (
            <Button
              key={hour}
              type={
                selectedHour === hour &&
                selectedDate &&
                selectedDate.toLocaleDateString() === date.toLocaleDateString()
                  ? "primary"
                  : "default"
              }
              disabled={!isSlotAvailable} // Désactive le bouton si le créneau n'est pas disponible
              onClick={isSlotAvailable ? () => handleHourClick(hour, date) : undefined}
              className={`cursor-pointer font-yusei text-center text-base rounded shadow-md ${
                selectedHour === hour &&
                selectedDate &&
                selectedDate.toLocaleDateString() === date.toLocaleDateString()
                  ? "bg-jb-primary"
                  : !isSlotAvailable
                  ? "cursor-not-allowed"
                  : "hover:bg-gray-100"
              }`}
            >
              {hour}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

export default AvailableHours;
