import { MoreOutlined } from "@ant-design/icons";
import { Dropdown, Table } from "antd";
import { ColumnsType } from "antd/es/table/interface";
import React, { useEffect, useState } from "react";
import ModalConfirm from "../../common/components/Modals/modal-confirm.component";
import Fieldset from "../../common/components/Panels/Fieldset";
import { useRefresh } from "../../common/contexts/RefreshContext";
import { ApiListResult } from "../../common/services/types";
import { openModalAction } from "../../common/state/modal.slice";
import { User } from "../../User/types/modelUser";
import adminApi from "../services/admin.api";
import { getUserMenuItems, MenuItemContactKeysEnum } from "./AdminListUserPage.misc";
import UserForm from "./Forms/UserForm";
import UserResume from "./UserResume";

type ModalActionType = (user: User) => void;
type DispatchFunction = (action: any) => void;
type TriggerRefreshFunction = () => void;


/**
 * Ouvre le modal pour modifier un contact
 * @param {User} user - Le contact à modifier
 * @param {Function} dispatch - La fonction dispatch pour gérer les actions Redux
 * @param {Function} triggerRefresh - Fonction pour rafraîchir les données après modification
 */
export const openModalContact = (user: User, dispatch: DispatchFunction, triggerRefresh: TriggerRefreshFunction) => {
  dispatch(
    openModalAction({
      component: <UserForm user={user} formCb={() => triggerRefresh()} />,
      options: { title: "Modifier un contact" },
    })
  );
};

/**
 * Ouvre le modal pour confirmer la suppression d'un contact
 * @param {User} user - Le contact à supprimer
 * @param {Function} dispatch - La fonction dispatch pour gérer les actions Redux
 * @param {Function} fetchUsersAdmin - Fonction pour rafraîchir la liste des utilisateurs après suppression
 */
export const openModalDeleteUser = (user: User, dispatch: DispatchFunction, fetchUsersAdmin: TriggerRefreshFunction) => {
  dispatch(
    openModalAction({
      component: (
        <ModalConfirm
          formCb={async (confirm) => {
            if (confirm) {
              await adminApi.deleteUser(user._id ?? "");
              fetchUsersAdmin();
            }
          }}
          confirmIsDanger
          body="Êtes-vous sûr de vouloir supprimer ce contact ?"
        />
      ),
      options: { title: "Supprimer un contact" },
    })
  );
};


export const itemClicked = (
  user: User,
  infos: any,
  openModalContact: ModalActionType,
  openModalDeleteUser: ModalActionType
) => {
  switch (infos.key) {
    case MenuItemContactKeysEnum.editUser:
      openModalContact(user);
      break;
    case MenuItemContactKeysEnum.deleteUser:
      openModalDeleteUser(user);
      break;
    default:
      break;
  }
};


/**
 * Composant de liste des utilisateurs administrateur avec les options pour gérer chaque utilisateur.
 */
const AdminListUser: React.FC = () => {
  const [accounts, setUsers] = useState<ApiListResult<User>>();
  const { refreshKey } = useRefresh();

  const fetchUsersAdmin = async () => {
    adminApi
      .findUsersAdmin({}, 1, 10)
      .then((res) => setUsers(res))
      .catch(console.log);
  };

  useEffect(() => {
    fetchUsersAdmin();
  }, [refreshKey]);

  const columns: ColumnsType<any> = [
    {
      title: "Nom",
      key: "lastName",
      render: (user: any) => (
        <div>
          <UserResume user={user} />
        </div>
      )
    },
    {
      title: "Téléphone",
      dataIndex: "mobile",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: <div className="text-center">Options</div>,
      key: "actions",
      render: (text: string, contact: any) => (
        <div className="text-center">
          <Dropdown menu={{ items: getUserMenuItems(contact, itemClicked as any) }}>
            <MoreOutlined data-testid={`more-options-${contact._id}`} />
          </Dropdown>
        </div>
      ),
    },
  ];

  return (
    <Fieldset title="Vue d'ensemble des rendez-vous : Admin">
      <Table
        rowKey={(record) => record._id}
        columns={columns}
        scroll={{ x: "max-content" }}
        pagination={{
          hideOnSinglePage: true,
          total: accounts?.count,
          pageSize: 5,
        }}
        dataSource={accounts?.rows || []}
      />
    </Fieldset>
  );
};


export default AdminListUser;
