import { Button } from "antd";
import PageCanvas from "../../common/components/Panels/PageCanvas";
import AdminListAppointment from "./AdminListAppointment";
import { closeModalAction, openModalAction } from "../../common/state/modal.slice";
import { useAppDispatch } from "../../common/hooks";
import AppointmentForm from "./Forms/AppointmentForm";

const AdminListAppointmentPage = () => {
  const dispatch = useAppDispatch();
  const openModalNewAppointment = () => {
    dispatch(
      openModalAction({
        component: <AppointmentForm formCb={
          () => dispatch(closeModalAction())
        } />,
        options: { title: "Ajouter un rendez-vous" },
      })
    );
  };

  return (
    <>
      <PageCanvas title="Liste des rendez-vous">
      <PageCanvas.Actions>
          <Button type="primary" onClick={openModalNewAppointment}>
            Ajouter un rendez-vous
          </Button>
        </PageCanvas.Actions>
        <PageCanvas.Content>
          <AdminListAppointment />
        </PageCanvas.Content>
      </PageCanvas>
    </>
  );
};

export default AdminListAppointmentPage;
