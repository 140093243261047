import tailwindTheme from "../../tailwind-theme";
import { TimelineStatusEnum } from "./types/LogTypes";

interface TypeTimelineItemProps {
  typeAction: TimelineStatusEnum;
}

const TypeTimelineItem = ({ typeAction }: TypeTimelineItemProps) => {
  let color;
  switch (typeAction) {
    case TimelineStatusEnum.create:
      color = tailwindTheme.colors.jb.timeline.create;
      break;
    case TimelineStatusEnum.read:
      color = tailwindTheme.colors.jb.timeline.read;
      break;
    case TimelineStatusEnum.update:
      color = tailwindTheme.colors.jb.timeline.update;
      break;
    case TimelineStatusEnum.delete:
      color = tailwindTheme.colors.jb.timeline.delete;
      break;
    default:
      color = "gray";
  }

  return <div style={{ backgroundColor: color }} className="w-5 h-5 rounded-full" />;
};

export default TypeTimelineItem;
