import { Button } from "antd";
import PageCanvas from "../../common/components/Panels/PageCanvas";
import AdminListServices from "./AdminListServices";
import { closeModalAction, openModalAction } from "../../common/state/modal.slice";
import { useAppDispatch } from "../../common/hooks";
import ServiceForm from "./Forms/ServiceForm";

const AdminListServicesPage = () => {
  const dispatch = useAppDispatch();
  const openModalNewService = () => {
    dispatch(
      openModalAction({
        component: <ServiceForm  formCb={
          () => dispatch(closeModalAction())
        } />,
        options: { title: "Ajouter un service" },
      })
    );
  };

  return (
    <>
      <PageCanvas title="Editer les services">
        <PageCanvas.Actions>
          <Button type="primary" onClick={openModalNewService}>
            Ajouter une prestation
          </Button>
        </PageCanvas.Actions>
        <PageCanvas.Content>
          <AdminListServices />
        </PageCanvas.Content>
      </PageCanvas>
    </>
  );
};

export default AdminListServicesPage;
