
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../../../common/hooks";
import { ROUTES } from "../../../common/router";
import { UserRolesEnum } from "../../../User/types/UserTypes";
import { selectIsLoggedIn, selectUser } from "../../state/auth.slice";
import LoginForm from "./LoginForm";

const LoginPage = () => {
  const { t } = useTranslation();
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const user = useAppSelector(selectUser);
  if (isLoggedIn && user?.role === UserRolesEnum.client) {
    return <>
      <Navigate to={ROUTES.CLIENT.DASHBOARD}></Navigate>
    </>
  }
  else if (isLoggedIn && user?.role === UserRolesEnum.admin) {
    return <>
      <Navigate to={ROUTES.ADMIN.DASHBOARD}></Navigate>
    </>
  }
  else if (isLoggedIn && user?.role === UserRolesEnum.esthetician) {
    return <>
      <Navigate to={ROUTES.ESTH.DASHBOARD}></Navigate>
    </>
  }
  return <>
    <h1 className="titleForm">{t('UI.LOGIN_PAGE.title')}</h1>
    <LoginForm></LoginForm>
  </>
}

export default LoginPage;