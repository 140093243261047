import React, { useState } from 'react';
import { FilePdfOutlined, DownloadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Invoice } from './services/types/modelInvoice';

interface InvoiceCardProps {
  invoice: Invoice;
}

const InvoiceCard: React.FC<InvoiceCardProps> = ({ invoice }) => {
  const [isHovered, setIsHovered] = useState(false);

  const downloadInvoice = async (invoiceId: string, fileName: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/invoices/${encodeURIComponent(invoiceId)}/download`,
        {
          responseType: 'blob', // Récupération des données binaires
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', sanitizeFileName(fileName));
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Erreur lors du téléchargement du fichier PDF', error);
    }
  };

  const sanitizeFileName = (name: string) => name.replace(/[^a-zA-Z0-9.\-_]/g, '_');

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => downloadInvoice(invoice._id!, invoice.fileName)}
      className="relative bg-white p-4 rounded-lg shadow-md cursor-pointer hover:shadow-lg transition-all duration-300 ease-in-out flex flex-col items-center space-y-3 border border-gray-200"
    >
      <FilePdfOutlined
        className={`text-4xl text-jb-timeline-delete transition-opacity duration-300 ${
          isHovered ? 'opacity-0' : 'opacity-100'
        }`}
      />

      <p className={`text-center text-base font-semibold transition-transform duration-300 ${isHovered ? 'translate-y-[10px]' : ''}`}>
        {invoice.fileName}
      </p>

      <div
        className={`absolute transition-transform duration-300 transform ${
          isHovered ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
        }`}
      >
        <DownloadOutlined className="text-4xl text-jb-timeline-delete" />
      </div>
    </div>
  );
};

export default InvoiceCard;