import { IAppointment } from "../../../Appointment/types/appointmentTypes";
import { IService } from "../../../Services/services/types/serviceTypes";
import { IUser } from "../../../User/types/UserTypes";
import { ILog, TimelineStatusEnum } from "./LogTypes";

export class Log implements ILog {
  userId!: IUser;
  typeAction!: TimelineStatusEnum;
  descriptionAction!: string;
  timestamp!: Date;
  serviceId!: IService;
  appointmentId!: IAppointment;

  id?: string | undefined;
  _id?: string | undefined;
  updatedAt?: Date | undefined;
  createdAt?: Date | undefined;

  public constructor(init?: Partial<Log>) {
    Object.assign(this, {
      ...init,
    });
  }
}
