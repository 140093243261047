import { Button, Empty, Table } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import { formatDate } from "../Appointment/Calendar/dateUtils";
import appointmentApi from "../Appointment/services/appointment.api";
import StatusBadge from "../Appointment/StatusBadge";
import { AppointmentStatusEnum } from "../Appointment/types/appointmentTypes";
import { Appointment } from "../Appointment/types/modelAppointment";
import cartApi from "../Cart/services/cart.api";
import ModalConfirm from "../common/components/Modals/modal-confirm.component";
import Fieldset from "../common/components/Panels/Fieldset";
import { useCart } from "../common/contexts/CartContext";
import { useAppDispatch } from "../common/hooks";
import { ApiListResult } from "../common/services/types";
import { openModalAction } from "../common/state/modal.slice";
import AppointmentDashboardClientMobile from "./AppointmentDashboardClientMobile";

interface DataType {
  key: string;
  service: string;
  date: string;
  status: string;
  action: string;
}

const AppointmentDashboardClient: React.FC<{
  viewAppointmentsArchived: boolean;
}> = ({ viewAppointmentsArchived }) => {
  const dispatch = useAppDispatch();

  const { cart, deleteAllFromCart } = useCart();
  const location = useLocation();
  const isMobile = useMediaQuery({ query: "(max-width: 1280px)" });

  // Extract query parameters
  const params = new URLSearchParams(location.search);
  const stripeTransactionId = params.get('stripe_transaction');

  useEffect(() => {
    fetchAppointments();
  }, []);

  const fetchAppointments = async () => {
    console.time('fetchAppointments'); // Start timing
    try {
      const res = await appointmentApi.findAppointments({}, 1, 10);
      setAppointments(res); // Mettez à jour l'état correctement
    } catch (error) {
      console.error(error);
    } finally {
      console.timeEnd('fetchAppointments'); // End timing and log the result
    }
  };
  
  useEffect(() => {
    const createAppointment = async () => {
      console.time('createAppointment'); // Start timing
      try {
        await cartApi.createAppointments(cart);
        await deleteAllFromCart();
        await fetchAppointments();
      } catch (error) {
        console.error("Error creating appointment:", error);
      } finally {
        console.timeEnd('createAppointment'); // End timing and log the result
      }
    };
  
    if (stripeTransactionId === 'success' && cart.rows.length > 0) {
      createAppointment();
    }
  }, [cart, stripeTransactionId, deleteAllFromCart]);
  
  const openModalCancelAppointment = (appointmentId: string) => {
    return openModalAction({
      component: (
        <ModalConfirm
          formCb={async (confirm) => {
            if (confirm === true) {
              await appointmentApi.cancelAppointment(
                appointmentId,
                AppointmentStatusEnum.cancelled.toString()
              );
              fetchAppointments();
            }
          }}
        />
      ),
      options: { title: "Êtes vous sûr de vouloir annuler ce rendez-vous ?" },
    });
  };

  const columns: any = [
    {
      title: "Service réservé",
      dataIndex: ["serviceId", "name"], // Accéder à la propriété name de l'objet serviceId
    },
    {
      title: "Date et heure ",
      dataIndex: "date",
    },
    {
      title: "Statut",
      dataIndex: "status",
      render: (text: string, appointment: any) => (
        <StatusBadge status={appointment.status} />
      ),
    },
    {
      title: <div className="text-center">Options</div>,
      key: "actions",
      render: (text: string, record: DataType) => (
        <div className="text-center">
          {Number(record.status) === AppointmentStatusEnum.confirmed && (
            <Button
              onClick={() => dispatch(openModalCancelAppointment(record.key))}
            >
              Annuler ce rendez-vous
            </Button>
          )}
        </div>
      ),
    },
  ];

  const [appointments, setAppointments] =
    useState<ApiListResult<Appointment>>();

  const upcomingAppointments = appointments?.rows.filter((appointment) =>
    dayjs(appointment.date).isAfter(dayjs())
  );
  const pastAppointments = appointments?.rows.filter((appointment) =>
    dayjs(appointment.date).isBefore(dayjs())
  );

  if (isMobile) {
    // sous forme de carte
    return (
      <>
      <AppointmentDashboardClientMobile
          upcomingAppointments={upcomingAppointments!}
          pastAppointments={pastAppointments}
          fetchAppointments={fetchAppointments}
        />
      </>
    );
  }

  return (
    <div>
      {viewAppointmentsArchived ? (
        <Fieldset title="Rendez-vous archivés">
          {pastAppointments && pastAppointments!.length > 0 ? (
            <Table
              rowKey={"_id"} // Ensure the unique identifier is correctly used
              showHeader={true}
              columns={columns}
              dataSource={
                pastAppointments!.map((appointment) => ({
                  key: appointment._id,
                  serviceId: appointment.serviceId,
                  date: formatDate(appointment.date),
                  status: appointment.status,
                  action: "Action",
                })) || []
              }
              scroll={{ x: "max-content" }}
              pagination={
                pastAppointments!.length > 10
                  ? { pageSize: 10 }
                  : { hideOnSinglePage: true }
              }
            />
          ) : (
            <Empty className="mt-52" description="Aucun rendez-vous archivé" />
          )}
        </Fieldset>
      ) : (
        <Fieldset title="Rendez-vous à venir">
          {upcomingAppointments && upcomingAppointments!.length > 0 ? (
            <Table
              rowKey={"_id"} // Ensure the unique identifier is correctly used
              showHeader={true}
              columns={columns}
              dataSource={
                upcomingAppointments!.map((appointment) => ({
                  key: appointment._id,
                  serviceId: appointment.serviceId,
                  date: formatDate(appointment.date),
                  status: appointment.status,
                  action: "Action",
                })) || []
              }
              scroll={{ x: "max-content" }}
              pagination={
                upcomingAppointments!.length > 10
                  ? { pageSize: 10 }
                  : { hideOnSinglePage: true }
              }
            
              
            />
          ) : (
            <Empty className="mt-52" description="Aucun rendez-vous à venir" />
          )}
        </Fieldset>
      )}
    </div>
  );
};

export default AppointmentDashboardClient;