// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fade-enter {
  transform: translateX(100%);
}

.fade-enter-active {
  transform: translateX(0);
  transition: transform 500ms;
}

.fade-exit {
  transform: translateX(0);
}

.fade-exit-active {
  transform: translateX(100%);
  transition: transform 500ms;
} `, "",{"version":3,"sources":["webpack://./src/Appointment/Calendar/Calendar.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;AAC7B;;AAEA;EACE,wBAAwB;EACxB,2BAA2B;AAC7B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,2BAA2B;EAC3B,2BAA2B;AAC7B","sourcesContent":[".fade-enter {\n  transform: translateX(100%);\n}\n\n.fade-enter-active {\n  transform: translateX(0);\n  transition: transform 500ms;\n}\n\n.fade-exit {\n  transform: translateX(0);\n}\n\n.fade-exit-active {\n  transform: translateX(100%);\n  transition: transform 500ms;\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
