import React from "react";
import { Button } from "antd";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";

interface WeekNavigationProps {
  currentWeek: Date[];
  goToPreviousWeek: (currentWeek: Date[]) => void;
  goToNextWeek: (currentWeek: Date[]) => void;
}

const WeekNavigation: React.FC<WeekNavigationProps> = ({ currentWeek, goToPreviousWeek, goToNextWeek }) => (
  <div className="flex justify-between w-full">
    <Button shape="circle" onClick={() => goToPreviousWeek(currentWeek)} icon={<CaretLeftOutlined />} disabled={currentWeek[0].getTime() < new Date().getTime()} />
    <div className="text-center font-bold">
      Semaine du {currentWeek[0].toLocaleDateString("fr-FR", { day: "numeric", month: "long" })} au {currentWeek[6].toLocaleDateString("fr-FR", { day: "numeric", month: "long" })}
    </div>
    <Button shape="circle" onClick={() => goToNextWeek(currentWeek)} icon={<CaretRightOutlined />} />
  </div>
);

export default WeekNavigation;
