import { ThemeConfig } from 'antd';
import tailwindTheme from './tailwind-theme';

const antdConfig: ThemeConfig = {
  hashed: false,
  token: {
    fontFamily: '"Noto Sans", sans-serif',
    // borderRadius: 4,
    colorPrimary: tailwindTheme.colors.jb.primary,
    // colorPrimaryHover: tailwindTheme.colors.jb.primaryHover,
    // colorInfo: "#02417d",
    // colorSuccess: tailwindTheme.colors.pz.success,
    // colorError: tailwindTheme.colors.pz.danger,
    // colorTextBase: tailwindTheme.colors.pz.dark,
  },
  components: {
    Form: {
      labelColor: tailwindTheme.colors.jb.secondary,
      fontSize: 14,
      lineHeight: 2,

      // colorErrorText: tailwindTheme.colors.pz.danger,
    },

    Input: {
      lineHeight: 2,
      lineWidthFocus: 2,
    },

    InputNumber: {
      // lineHeight: 2.75,
      // colorBgContainer: tailwindTheme.colors.gray[100],
      // colorBorder: 'transparent',
    },
    Button: {
      lineHeight: 0,
      fontSize: 16,
      primaryShadow: undefined,
      primaryColor: tailwindTheme.colors.white,
      colorPrimaryBg: tailwindTheme.colors.jb.primary,
      fontFamily: tailwindTheme.fontFamily.antic[0],
    },
    Modal: {
      titleColor: tailwindTheme.colors.jb.primary,
      titleFontSize: 18,
      titleLineHeight: 2,
      fontWeightStrong: 200
    },
    Drawer: {
      colorText: tailwindTheme.colors.jb.primary,
      fontWeightStrong: 200,
      fontSizeLG: 18,
    },
    Select: {
      optionSelectedBg: tailwindTheme.colors.jb.primary,
      optionSelectedColor: "white",
      optionSelectedFontWeight: 'normal',
      // controlHeight: 40,
      colorBorder: 'transparent',
    },
    DatePicker: {
      lineHeight: 2,
      colorBgContainer: tailwindTheme.colors.gray[100],
      colorBorder: 'transparent',
      controlHeight: 30
    },
    Menu: {
      boxShadow: 'none'
    },
    Statistic: {
      contentFontSize: 24,
      fontSize: 16,
    }

  },
}

export default antdConfig;