export enum UserRolesEnum {
  client = "client",
  esthetician = "esthetician",
  admin = "admin",
}

export interface IUser {
  email: string;
  password?: string;
  role: UserRolesEnum;
  firstName?: string;
  lastName?: string;
  mobile?: string;
  picture?: string;
  resetPasswordToken?: string;
  resetPasswordExpires?: Date;


  id?: string;
  _id?: string;
  updatedAt?: Date;
  createdAt?: Date;
}

export interface IUserRef {
  userId: IUser["_id"];
}
