
import { useTranslation } from "react-i18next";
import ResetPasswordForm from "./ResetPasswordForm";

const ResetPasswordPage = () => {
  const { t } = useTranslation();
  return <>
    <h1 className="titleForm">{t('UI.RESET_PASSWORD_PAGE.title')}</h1>
    <ResetPasswordForm/>
  </>
}

export default ResetPasswordPage;