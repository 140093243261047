import { Badge } from 'antd';
import tailwindTheme from '../tailwind-theme';
import { AppointmentStatusEnum, AppointmentStatusEnumLabels } from './types/appointmentTypes';

interface StatusBadgeProps {
  status: AppointmentStatusEnum;
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ status }) => {
  switch (status) {
    case AppointmentStatusEnum.cancelled:
      return (
        <Badge
          count={AppointmentStatusEnumLabels[status]}
          color={tailwindTheme.colors.jb.status["-1"]}
        />
      );
    case AppointmentStatusEnum.confirmed:
      return (
        <Badge
          count={AppointmentStatusEnumLabels[status]}
          color={tailwindTheme.colors.jb.status["2"]}
        />
      );
    case AppointmentStatusEnum.finished:
      return (
        <Badge
          count={AppointmentStatusEnumLabels[status]}
          color={tailwindTheme.colors.jb.status["3"]}
        />
      );
    default:
      return <span>Unknown</span>;
  }
}

export default StatusBadge;