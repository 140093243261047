import { Drawer, Modal } from "antd";
import { useAppDispatch, useAppSelector } from "../hooks";
import { closeDrawerAction, closeModalAction, selectDrawer, selectModal } from "../state/modal.slice";

const ModalService = () => {
  const modal = useAppSelector(selectModal);
  const drawer = useAppSelector(selectDrawer);

  const dispatch = useAppDispatch();

  const closeModal = () => {
    dispatch(closeModalAction());
  }

  const closeDrawer = () => {
    dispatch(closeDrawerAction());
  }

  return <>
    <Drawer
      open={drawer.isDrawerOpen}
      // size={"large"}
      placement="right"
      onClose={_ => closeDrawer()}
      {...drawer.drawerOptions}
    >
      {drawer.isDrawerOpen && drawer.drawerComponent}
    </Drawer>

    <Modal
      forceRender={true}
      open={modal.isModalOpen}
      footer={false}
      zIndex={1000000}
      onCancel={_ => closeModal()}
      {...modal.modalOptions}
    >
      {modal.modalComponent}
    </Modal >


  </>
}

export default ModalService