import React, { useState, useEffect } from 'react';
import { Collapse, Empty, Pagination } from 'antd'; // Utilisation de Collapse et Pagination d'Ant Design
import invoiceApi from './services/invoice.api';
import { Invoice } from './services/types/modelInvoice';
import { ApiListResult } from '../common/services/types';
import dayjs from 'dayjs';
import InvoicePanel from './InvoicePanel';
import { DownloadOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const InvoiceList = () => {
  const [invoices, setInvoices] = useState<ApiListResult<Invoice>>();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5; // Nombre de panneaux par page

  const fetchInvoices = async () => {
    try {
      const res = await invoiceApi.findInvoices({}, 1, 10);
      setInvoices(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchInvoices();
  }, []);

  // Fonction pour regrouper les factures par date (createdAt)
  const groupInvoicesByDate = () => {
    if (!invoices) return {};

    return invoices.rows.reduce((groups: { [key: string]: Invoice[] }, invoice: Invoice) => {
      const date = dayjs(invoice.createdAt).format('YYYY-MM-DD');
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(invoice);
      return groups;
    }, {});
  };

  const groupedInvoices = groupInvoicesByDate();
  const dates = Object.keys(groupedInvoices);

  // Calculer les factures à afficher en fonction de la page actuelle
  const currentDates = dates.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <div>
      {dates.length === 0 ? (
        <Empty description="Aucune facture disponible pour le moment" />
      ) : (
        <div>
          <h1>Liste des factures</h1>

          <Collapse defaultActiveKey={['0']} expandIconPosition="right">
            {currentDates.map((date, index) => (
              <Panel
                header={
                  <div className="flex items-center space-x-3">
                    <DownloadOutlined className="text-2xl text-jb-timeline-delete" />
                    <span>{`Factures du ${date}`}</span>
                  </div>
                }
                key={index}
              >
                <InvoicePanel invoices={groupedInvoices[date]} />
              </Panel>
            ))}
          </Collapse>

          {/* Pagination */}
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={dates.length} // Total des dates pour calculer le nombre de pages
            onChange={(page) => setCurrentPage(page)}
            style={{ marginTop: '20px', textAlign: 'center' }}
          />
        </div>
      )}
    </div>
  );
};

export default InvoiceList;
