import { buildFindQuery } from "../tools/global-tools";
import apiClient from "./apiClient";
import { trackProgress } from "./trackProgress";
import { ApiListResult } from "./types";

// La classe générique ApiService permet de gérer les requêtes API pour différents types de ressources (généralisé par <K>)
class ApiService<K> {
  // Chemin de base de l'API, à configurer lors de l'instanciation
  path!: string;
  // Définition des zones de suivi du progrès des requêtes, pour identifier chaque type d'opération
  public trackProgressAreas = {
    find: `GET_${this.path}`,
    get: `GET_${this.path}:id`,
    update: `PUT_${this.path}:id`,
    create: `POST_${this.path}`,
    delete: `DELETE_${this.path}:id`,
    custom: (method: string) => `${method.toUpperCase()}_custom_${this.path}:id`,
  };
  // Constructeur pour configurer le chemin API (path)
  constructor(path: string) {
    // Assurer que le chemin commence par un slash
    if (path[0] !== '/')
      this.path = '/' + path;
    // Assurer que le chemin se termine par un slash
    if (path[path.length - 1] !== '/')
      this.path += '/';
  }
  // Fonction pour rechercher des éléments avec pagination
  protected find = ({ query = {}, page = 1, pageSize = 10 }): Promise<ApiListResult<K>> => {
    const myQuery = { ...query };  // Clonage de la requête
    // Construction de la chaîne de requête
    const queryString = buildFindQuery(myQuery);
    return trackProgress(
      // Appel GET vers l'API avec pagination
      apiClient.get<ApiListResult<K>>(`${this.path.replace(/\/$/, "")}?${queryString}&page=${page}&limit=${pageSize}`),
      this.trackProgressAreas.find  // Suivi de l'état de la requête
    ).then(r => r.data);  // Retourne les données de la réponse
  }

  // Fonction pour récupérer un élément unique par son ID
  protected get = (id: string): Promise<K> => {
    return trackProgress(
      // Appel GET avec l'ID dans l'URL
      apiClient.get<K>(`${this.path}${id}`),
      this.trackProgressAreas.get  // Suivi de l'état de la requête
    ).then(r => r.data);  // Retourne les données de la réponse
  }

  // Fonction pour créer un nouvel élément
  protected create = (data: Partial<K>) => {
    return trackProgress(
      // Appel POST pour envoyer les données à créer
      apiClient.post<K>(`${this.path}`, data),
      this.trackProgressAreas.create  // Suivi de l'état de la requête
    ).then(r => r.data);  // Retourne les données de la réponse
  }

  // Fonction pour mettre à jour un élément existant par son ID
  protected update = (id: string, data: Partial<K>) => {
    return trackProgress(
      // Appel PUT pour mettre à jour les données d'un élément existant
      apiClient.put<K>(`${this.path}${id}`, data),
      this.trackProgressAreas.update  // Suivi de l'état de la requête
    ).then(response => {
      return response.data;  // Retourne les données mises à jour
    });
  }

  // Fonction pour supprimer un élément par son ID
  protected delete = (id: string, data?: Partial<K>) => {
    return trackProgress(
      // Appel DELETE pour supprimer un élément
      apiClient.delete<K>(`${this.path}${id}`, data),
      this.trackProgressAreas.delete  // Suivi de l'état de la requête
    ).then(response => {
      return response.data;  // Retourne la réponse de la suppression
    });
  }

  // Fonction personnalisée pour des requêtes spécifiques (méthodes GET, PUT, POST, DELETE)
  protected custom = <R>(
    method: 'get' | 'put' | 'post' | 'delete' = 'get',  // Type de méthode HTTP
    path: string,  // Chemin spécifique
    params?: any,  // Paramètres éventuels pour les URL dynamiques
    data?: Partial<K> | any,  // Données pour les requêtes PUT/POST
    headers?: Record<string, string>  // En-têtes personnalisés
  ) => {
    // Extraction des noms de paramètres dynamiques dans le chemin
    const paramNames = path.match(/:([^/?]+)/g);
    let query = path;
    // Remplacement des paramètres dans le chemin avec les valeurs fournies
    paramNames?.forEach(paramName => query = query.replace(paramName, params[paramName.replace(':', '')]));

    // Détermination de la méthode (POST/PUT envoie des données, GET/DELETE pas nécessairement)
    const call = ['put', 'post'].includes(method) ?
      apiClient[method]<R>(`${this.path}${query}`, data, { headers }) :
      apiClient[method]<R>(`${this.path}${query}`, { headers });

    return trackProgress(
      // Appel API avec suivi de progression
      call,
      this.trackProgressAreas.custom(method)  // Suivi de l'état de la requête personnalisée
    ).then(response => {
      return response.data;  // Retourne la réponse
    });
  }

}

export default ApiService;
