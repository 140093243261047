import { call, put, select, takeLatest } from "redux-saga/effects";
import { RootState } from "../../common/store";
import authService from "../services/auth.api";
import { AppAuth } from "../types";
import {
  loginAction,
  loginErrorAction,
  loginSuccessAction,
  refreshTokenAction,
  registerAction,
  registerErrorAction,
  registerSuccessAction,
} from "./auth.slice";

function* login({
  payload: { email, password },
}: ReturnType<typeof loginAction>) {
  try {
    const resp: AppAuth = yield call(authService.login, email, password);
    yield put(loginSuccessAction(resp));
  } catch (err: any) {
    let errorMessage = "Une erreur s'est produite lors de la connexion";
    if (err.response && err.response.status === 401) {
      errorMessage = "Le mot de passe est incorrect";
    }
    yield put(loginErrorAction(errorMessage)); // Passez un message d'erreur clair ici
  }
}


function* refresh({ payload }: ReturnType<typeof refreshTokenAction>) {
  try {
    const {
      authService: { auth },
    }: RootState = yield select();

    const token = auth?.token;
    if (token) {
      const resp: AppAuth = yield call(authService.refreshToken, token);

      yield put(loginSuccessAction(resp));
    }
  } catch (err) {
    yield put(loginErrorAction(err as string));
  }
}

function* register({
  payload: { firstName, lastName, mobile, email, password },
}: ReturnType<typeof registerAction>) {
  try {
    const resp: AppAuth = yield call(
      authService.register,
      firstName,
      lastName,
      mobile,
      email,
      password
    );
    yield put(registerSuccessAction(resp));
    yield put(refreshTokenAction());
  } catch (err) {
    yield put(registerErrorAction(err));
  }
}

export function* authSaga() {
  yield takeLatest(loginAction, login);
  yield takeLatest(registerAction, register);
  yield takeLatest(refreshTokenAction, refresh);
}
