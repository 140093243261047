import axios, { AxiosError } from 'axios';

import { logoutAction, selectToken } from '../../Auth/state/auth.slice';
import { store } from '../store';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});


apiClient.interceptors.request.use(async (config: any) => {
  const token = selectToken(store.default.getState());
  if (token && config) {
    config.headers = Object.assign((config.headers || {}), { Authorization: `Bearer ${token}` });
  }

  return config;
});

apiClient
  .interceptors
  .response
  .use(
    (response: any) => response,
    (error: AxiosError) => {
      if (error && error.response && [401, 403].indexOf(error.response.status) >= 0) {
        store.default.dispatch(logoutAction());
        return Promise.reject(error);
      }

      throw error;
    }
  );
export default apiClient;
