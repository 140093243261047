import { defaultPagination } from "../../common/app-constants";
import ApiService from "../../common/services/apiService";
import { IInvoice } from "./types/invoiceTypes";
import { Invoice } from "./types/modelInvoice";

const INVOICE_PATH_API = "invoices";

class InvoiceApi extends ApiService<IInvoice> {
  constructor() {
    super(INVOICE_PATH_API);
  }

  findInvoices = (
    query = {},
    page = defaultPagination.page,
    pageSize = defaultPagination.pageSize
  ): Promise<{ count: number; rows: IInvoice[] }> => {
    return this.find({ query, page, pageSize }).then((res) => {
      return {
        count: res.count,
        rows: res.rows.map((r) => new Invoice(r)),
      };
    });
  };


    
}

const invoiceApi = new InvoiceApi();

export default invoiceApi;
