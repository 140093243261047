import React from "react";

type WeekHeaderProps = {
  currentWeek: Date[];
  daysOfWeek: string[];
};

const WeekHeader: React.FC<WeekHeaderProps> = ({ currentWeek, daysOfWeek }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-7 gap-8 p-4 sticky top-0 bg-white z-10">
      {daysOfWeek.map((day, index) => (
        <div key={index} className="flex flex-col text-center">
          <span className="lowercase text-base">{day}</span>
          <span className="text-base">
            {currentWeek[index].getDate()}{" "}
            {currentWeek[index].toLocaleDateString("fr-FR", {
              month: "short",
            })}
          </span>
        </div>
      ))}
    </div>
  );
};

export default WeekHeader;
